import React from "react";

const EventFooterAbout6ix = () => {
  return (
    <div className="events6ix-layout-footer-content-right-box">
      <div className="events6ix-layout-footer-content-right-box-heading">
        ABOUT 6ix
      </div>
      <div className="events6ix-layout-footer-content-right-box-text">
        <div className="events6ix-layout-footer-content-space">
        6ix is the platform where companies, thought leaders, and investors come together to build thriving investment communities.        </div>
        <div className="events6ix-layout-footer-content-space">
        Our mission is to transform how the investment world connects by giving anyone the power to create their own branded community space where they can share their story, engage with supporters, and build meaningful relationships.        </div>
        <div className="events6ix-layout-footer-content-space">
        For companies and founders, we provide the tools to communicate directly with investors who believe in your vision. For investors, we offer access to opportunities and insights from leaders you trust. And for experts, we enable you to build and monetize your thought leadership.
        With our easy-to-use platform, you can create your own investor community in minutes—whether you're a pre-IPO startup, a public company CEO, or an investment thought leader. Start with our free plan and scale as you grow.
      
        </div>
        Join the 400+ innovative leaders and 100,000+ active investors already building connections that matter on 6ix.      </div>
    </div>
  );
};

export default EventFooterAbout6ix;
