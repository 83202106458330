import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, List, ListItemButton, Menu } from "@mui/material";
import { Box } from "@100mslive/roomkit-react";
import { useGlobalValue } from "../../../GlobalContext";
import { CheckMark, LogoIcon, Plus, SortIcon } from "../../../icons";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { SESSION_AUTH } from "../../../common/auth";
import { ACCOUNT_SERVICES, USER_SERVICES } from "../../../services";

const EventHeaderAccounts = () => {
  const { setLoginUserVerify } = useGlobalValue();
  const { isUserSession, UPDATE_USER_SESSION, LOGOUT_USER_SESSION } =
    SESSION_AUTH();

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [headerMenuEl, setHeaderMenuEl] = useState(null);
  const headerMenuOpen = Boolean(headerMenuEl);
  const [userAccountsList, setUserAccountsList] = useState(null);

  const navigate = useNavigate();
  const headerMenuOpenReq = event => {
    if (!userData?.isVerify) {
      setLoginUserVerify(true);
      return;
    }
    setHeaderMenuEl(event.currentTarget);
    if (!userAccountsList) getUserAccountsList();
  };
  const headerMenuClose = () => {
    setHeaderMenuEl(null);
  };

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
      setUserAccountsList("");
    }
    return () => null;
  }, [isUserSession]);

  if (!isUserSession) {
    return;
  }
  const getUserAccountsList = () => {
    try {
      ACCOUNT_SERVICES.getList({
        userId: userData?.userId,
        org: userData?.org?.id,
      })
        .then(data => {
          if (data.code === 200) {
            if (data?.data && data?.data?.length > 0) {
              setUserAccountsList(data?.data);
            }
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
            setHeaderMenuEl(null);
          }
        })
        .catch(err => {
          ToastManager.addToast({ title: err.message });
        });
    } catch (err) {
      ToastManager.addToast({ title: err.message });
    }
  };
  const createUserOrgAccount = () => {
    try {
      setLoading(true);
      headerMenuClose();
      ACCOUNT_SERVICES.create({
        userId: userData?.userId,
        org: userData?.org?.id,
      })
        .then(data => {
          if (data.code === 200) {
            setUserAccountsList("");
            switchUserAccount(data.data);
            return;
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
          setLoading(false);
        })
        .catch(err => {
          ToastManager.addToast({ title: err.message });
          setLoading(false);
        });
    } catch (err) {
      ToastManager.addToast({ title: err.message });
    }
  };

  const switchUserAccount = item => {
    if (item?.accountId === userData?.org?.id) return;
    setLoading(true);
    headerMenuClose();
    setUserAccountsList(null);
    USER_SERVICES.switchAccount({
      userId: userData?.userId,
      org: userData?.org?.id,
      switch: item?.accountId,
    })
      .then(data => {
        if (data.code === 200) {
          UPDATE_USER_SESSION({ org: data?.data });
          navigate("/R_C_P", { state: { replace: true } });
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data.message });
        }
      })
      .catch(error => {
        ToastManager.addToast({ title: error.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      {loading && <FullPageProgress fixed={true} />}
      <div
        className={`events6ix-layout-header-right-item-accounts-btn ${
          headerMenuOpen ? "active" : ""
        }`}
        id="header-accounts-menu-button"
        aria-controls={headerMenuOpen ? "header-accounts-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={headerMenuOpen ? "true" : undefined}
        onClick={headerMenuOpenReq}
      >
        <div
          className="events6ix-layout-header-right-item-accounts-btn-logo"
          style={{
            backgroundColor: userData?.org?.orgLogo
              ? userData?.org?.orgLogoColor
              : "",
          }}
        >
          {userData?.org?.orgLogo ? (
            <img src={userData?.org?.orgLogo} alt="logo" />
          ) : (
            <LogoIcon />
          )}
        </div>
        <div
          className="events6ix-layout-header-right-item-accounts-btn-name"
          translate="no"
        >
          {userData?.org?.orgName}
        </div>
        <div className="events6ix-layout-header-right-item-accounts-btn-sort">
          <SortIcon />
        </div>
      </div>

      <Menu
        id="header-accounts-menu"
        anchorEl={headerMenuEl}
        open={headerMenuOpen}
        onClose={headerMenuClose}
        MenuListProps={{
          "aria-labelledby": "header-accounts-menu-button",
        }}
        className="events6ix-layout-header-right-item-dropdown events6ix-layout-header-right-item-accounts"
      >
        <List
          component="nav"
          className="events6ix-layout-header-right-item-accounts-list"
        >
          {userAccountsList ? (
            userAccountsList?.map((item, key) => (
              <ListItemButton
                key={key}
                className="events6ix-layout-header-right-item-accounts-list-item"
                onClick={() => {
                  switchUserAccount(item);
                }}
                title={item?.orgName}
              >
                <div className="events6ix-layout-header-right-item-accounts-list-item-logo">
                  {item?.orgLogo ? (
                    <img src={item.orgLogo} alt="logo" />
                  ) : (
                    <LogoIcon />
                  )}
                </div>
                <div
                  className="events6ix-layout-header-right-item-accounts-list-item-name"
                  translate="no"
                >
                  {item?.orgName}
                </div>
                {item.accountId === userData?.org?.id && (
                  <div className="events6ix-layout-header-right-item-accounts-list-item-check">
                    <CheckMark />
                  </div>
                )}
              </ListItemButton>
            ))
          ) : (
            <Box className="events6ix-layout-header-right-item-accounts-list-loader">
              <CircularProgress />
            </Box>
          )}
        </List>
        {userAccountsList ? (
          <Box
            onClick={createUserOrgAccount}
            className="events6ix-layout-header-right-item-accounts-footer"
          >
            <Plus /> Create Org
          </Box>
        ) : null}
      </Menu>
    </>
  );
};

export default EventHeaderAccounts;
