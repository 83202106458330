import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { ExitIcon } from "@100mslive/react-icons";
import { Box, Flex, Text } from "@100mslive/roomkit-react";
import { ToastManager } from "./Toast/ToastManager";
import { Star } from "../icons";
import FullPageProgress from "./FullPageProgress";
import { Header } from "./Header";
import { useSidepaneReset } from "./AppData/useSidepane";
// import {
//   defaultPreviewPreference,
//   UserPreferencesKeys,
//   useUserPreferences,
// } from "./hooks/useUserPreferences";
import { SESSION_AUTH } from "../common/auth";
import { EVENT_SERVICES } from "../services";

const PostLeave = () => {
  const navigate = useNavigate();
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const resetSidePane = useSidepaneReset();
  const { roomId } = useParams();
  // const [previewPreference] = useUserPreferences(
  //   UserPreferencesKeys.PREVIEW,
  //   defaultPreviewPreference
  // );
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState(0);
  const startCount = [1, 2, 3, 4, 5];
  const quality = ["", "poor", "fair", "good", "very good", "outstanding"];

  const onRating = v => {
    if (v) {
      setRating(v);
      setLoading(true);
      EVENT_SERVICES.ratingSave({
        userId: isUserSession.userId,
        org: isUserSession?.org?.id,
        urlSlug: roomId,
        rating: v,
      })
        .then(data => {
          if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else if (data.code === 400) {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <Flex
      direction="column"
      css={{ size: "100%" }}
      className="events6ix-layout"
    >
      {loading ? <FullPageProgress fixed={true} /> : null}
      <Flex
        direction="column"
        css={{ size: "100%" }}
        className="events6ix-layout-page-content"
      >
        <Box css={{ h: "$18", "@md": { h: "$17" } }} data-testid="header">
          <Header isPreview />
        </Box>
        <Flex
          justify="center"
          direction="column"
          align="center"
          className="eventsLeave"
        >
          <Button
            onClick={() => {
              ToastManager.clearAllToast();
              resetSidePane();
              navigate("/event/" + roomId, { replace: true });
            }}
            variant="outlined"
            data-testid="join_again_btn"
            className="events6ix-btn events6ix-btn-light"
            startIcon={<ExitIcon />}
          >
            Rejoin
          </Button>
          {rating && !loading ? (
            <div className="eventsLeave-call">
              <Text variant="h4" className="eventsLeave-call-heading">
                Thanks for the feedback!
              </Text>
              <div className="eventsLeave-call-rating">
                <div className="eventsLeave-call-rating-feedback">🤝</div>
              </div>
            </div>
          ) : (
            <div className="eventsLeave-call">
              <Text variant="h4" className="eventsLeave-call-heading">
                How was the call quality?
              </Text>
              <div className="eventsLeave-call-rating">
                {startCount.map((c, key) => {
                  return (
                    <div
                      key={key}
                      className="eventsLeave-call-rating-star"
                      onClick={() => onRating(c)}
                      title={`The quality was ${quality?.[c]}`}
                    >
                      <Star active={rating >= c} />
                    </div>
                  );
                })}
              </div>
              {rating ? (
                <Text
                  variant="body1"
                  css={{
                    color: "$textMedEmp",
                    mt: "$8",
                    fontWeight: "$regular",
                    textAlign: "center",
                  }}
                  className="eventsLeave-call-text"
                >
                  The quality was {quality?.[rating]}
                  {/* {previewPreference.name && (
                  <Box as="span" css={{ ...textEllipsis(100) }}>
                    , {previewPreference.name}
                  </Box>
                )} */}
                  !
                </Text>
              ) : null}
            </div>
          )}
          <div className="eventsLeave-access">
            <Text variant="h5" className="eventsLeave-access-heading">
              6ix is Your All-Access Pass to the Investment World
            </Text>
            <Text variant="body1" className="eventsLeave-access-text">
              invest, raise capital, and share expertise on a global stage.
            </Text>

            <Button
              variant="outlined"
              data-testid="join_again_btn"
              className="events6ix-btn events6ix-btn-primary"
              onClick={() => {
                ToastManager.clearAllToast();
                resetSidePane();
                navigate("/demo", { replace: true });
              }}
            >
              get a demo
            </Button>
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PostLeave;
