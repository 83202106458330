import React, { useEffect, useRef, useState } from "react";
import { Flex, Input } from "@100mslive/roomkit-react";

const PinBox = props => {
  const { updatePin } = props;
  const [pin, setPin] = useState(["", "", "", ""]);

  const inputRefs = useRef([]);

  const pinChange = (value, index) => {
    if (!/^\d?$/.test(value)) return;
    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);
    if (value && index < 3) {
      inputRefs.current[index + 1].focus();
    }
  };

  const pinBackspace = (event, index) => {
    if (event.key === "Backspace" && !pin[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const pinPaste = event => {
    event.preventDefault();
    const pasteData = event.clipboardData
      .getData("text")
      .replace(/\D/g, "")
      .slice(0, 4);

    const newPin = [...pin];
    for (let i = 0; i < pasteData.length; i++) {
      newPin[i] = pasteData[i];
    }
    setPin(newPin);
    const lastFilledIndex = pasteData.length - 1;
    if (lastFilledIndex < 4) {
      inputRefs.current[lastFilledIndex]?.focus();
    }
  };

  useEffect(() => {
    if (updatePin) {
      updatePin(pin?.join(""));
    }
  }, [pin, updatePin]);

  return (
    <Flex className="custom-form-pin">
      {pin.map((digit, index) => (
        <Input
          key={index}
          type="tel"
          maxLength="1"
          placeholder="*"
          className="custom-form-input custom-form-pin-input"
          value={digit}
          onChange={e => pinChange(e.target.value, index)}
          onKeyDown={e => pinBackspace(e, index)}
          onPaste={pinPaste}
          ref={el => (inputRefs.current[index] = el)}
        />
      ))}
    </Flex>
  );
};

export default PinBox;
