import React, { useEffect, useState } from "react";
import { useMedia } from "react-use";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@mui/material";
import { config as cssConfig, Flex, Input } from "@100mslive/roomkit-react";
import ProfileBox from "../Common/ProfileBox";
import FullPageProgress from "../FullPageProgress";
import { SESSION_AUTH } from "../../common/auth";
import { INVESTOR_TYPE_LIST } from "../../common/utils";

const EventSurveypopup = props => {
  const { isUserSession } = SESSION_AUTH();
  const {
    surveyData,
    setSurveyData,
    saveSurvey,
    eventDetail,
    visibleSurveyField,
    loading,
    eventMeetingReq,
  } = props;

  const sliderRangeno = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  const [hostedLabel, setHostedLabel] = useState("");
  useEffect(() => {
    let hostText = "";
    if (eventDetail?.hostedBy?.length > 0) {
      for (let value of eventDetail.hostedBy) {
        if (value?.allow) {
          hostText += hostText ? ", " + value?.orgName : value?.orgName;
        }
      }
    }
    setHostedLabel(hostText);
  }, [eventDetail]);
  const isMobile = useMedia(cssConfig.media.md);

  return (
    <Flex
      align="center"
      direction="column"
      className="eventsPopup eventsPopup-sm attenderegisterationpopup"
    >
      <div className="eventsPopup-inner">
        <div className="eventsPopup-header">
          <div className="eventsPopup-header-heading2">
            You are successfully registered! {isMobile ? <br /> : null} One
            final step...
          </div>
        </div>
        {loading ? <FullPageProgress fixed={true} /> : null}

        <div className="eventsPopup-content">
          <ProfileBox editAllow={true} />
          <Flex direction="column" className="attenderegisterationpopup-form">
            <Flex
              className="attenderegisterationpopup-form-inputarea"
              direction="column"
            >
              <Flex
                direction="column"
                className="attenderegisterationpopup-form-inputarea-choose"
              >
                {isUserSession?.isVerify ? (
                  <React.Fragment>
                    {!visibleSurveyField.work ? (
                      <FormControl className="custom-form-group">
                        <label
                          htmlFor="survey-work"
                          className="custom-form-label"
                        >
                          Company name
                        </label>
                        <Input
                          id="survey-work"
                          name="work"
                          placeholder="Company name"
                          className="custom-form-input"
                          value={surveyData.work}
                          onChange={e =>
                            setSurveyData(old => ({
                              ...old,
                              work: e.target.value,
                            }))
                          }
                        />
                      </FormControl>
                    ) : null}
                    {!visibleSurveyField.jobTitle ? (
                      <FormControl className="custom-form-group">
                        <label
                          htmlFor="survey-jobTitle"
                          className="custom-form-label "
                        >
                          Job title
                        </label>
                        <Input
                          id="survey-jobTitle"
                          name="jobTitle"
                          placeholder="Job title"
                          className="custom-form-input"
                          value={surveyData.jobTitle}
                          onChange={e =>
                            setSurveyData(old => ({
                              ...old,
                              jobTitle: e.target.value,
                            }))
                          }
                        />
                      </FormControl>
                    ) : null}

                    {!visibleSurveyField.investorType ? (
                      <FormControl className="custom-form-group ">
                        <label
                          htmlFor="survey-investorType"
                          className="custom-form-label"
                        >
                          Investor Type
                        </label>

                        <Select
                          id="survey-investorType"
                          name="investorType"
                          className="custom-select"
                          sx={{
                            height: 45,
                            background: "#131313",
                            borderRadius: "8px",
                          }}
                          value={surveyData?.investorType || ""}
                          onChange={e => {
                            setSurveyData({
                              ...surveyData,
                              investorType: e.target.value,
                              typicalSize:
                                INVESTOR_TYPE_LIST?.[e.target.value]?.size || 0,
                            });
                          }}
                          displayEmpty
                        >
                          <MenuItem disabled value="">
                            <span>Select</span>
                          </MenuItem>
                          {Object.keys(INVESTOR_TYPE_LIST).map(
                            (item, index) => (
                              <MenuItem
                                key={index}
                                value={INVESTOR_TYPE_LIST[item].value}
                              >
                                {INVESTOR_TYPE_LIST[item].name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    ) : null}
                  </React.Fragment>
                ) : null}
                <FormControl className="custom-form-group mb-0">
                  <label
                    htmlFor="survey-feedback"
                    className="custom-form-label big-label"
                  >
                    You may use this box to submit questions, comments and
                    feedback related to the event:
                  </label>
                  <TextareaAutosize
                    id="survey-feedback"
                    name="feedback"
                    value={surveyData?.feedback}
                    onChange={e =>
                      setSurveyData(old => ({
                        ...old,
                        feedback: e.target.value,
                      }))
                    }
                    className="custom-form-input"
                    placeholder="Type your question or comment here..."
                    minRows={2}
                    maxRows={3}
                  />
                </FormControl>
              </Flex>
              <Flex
                direction="column"
                className="attenderegisterationpopup-form-inputarea-choose"
              >
                <span>
                  How likely are you to recommend {hostedLabel} to a friend?
                </span>
                <Flex direction="column" css={{ position: "relative" }}>
                  <Flex
                    css={{ width: "100%", justifyContent: "space-between" }}
                  >
                    {sliderRangeno.map((data, key) => {
                      return (
                        <Flex
                          direction="column"
                          style={{
                            gap: "7px",
                          }}
                          key={key}
                        >
                          <span
                            className={
                              surveyData?.likelyNumber === data
                                ? "highlightslider_data"
                                : " "
                            }
                          >
                            {data}
                          </span>
                          <span
                            style={{
                              width: "2px",
                              height: "26px",
                              background: "#2B2B2B",
                            }}
                          ></span>
                        </Flex>
                      );
                    })}
                  </Flex>
                  <input
                    className="attenderegisterationpopup-form-inputarea-choose-rangeslider"
                    type="range"
                    value={surveyData?.likelyNumber}
                    min="1"
                    max="10"
                    onChange={e =>
                      setSurveyData(old => ({
                        ...old,
                        likelyNumber: e.target.value,
                      }))
                    }
                  />
                  <div className="attenderegisterationpopup-form-inputarea-choose-coustemrangeslider">
                    <div
                      className="attenderegisterationpopup-form-inputarea-choose-coustemrangeslider-inner"
                      style={{ width: `${surveyData?.likelyNumber * 10}%` }}
                    ></div>
                  </div>
                </Flex>
              </Flex>
              <Flex
                direction="column"
                className="attenderegisterationpopup-form-inputarea-choose"
              >
                <span>Are you currently invested in {hostedLabel}?</span>
                <Flex align="center" css={{ gap: "8px" }}>
                  <button
                    type="button"
                    className={`attenderegisterationpopup-form-inputarea-choose-btn 
                      ${surveyData.currentlyInvested === "yes" ? ` active` : ""}
                    `}
                    onClick={() =>
                      setSurveyData(old => ({
                        ...old,
                        currentlyInvested: "yes",
                      }))
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className={`attenderegisterationpopup-form-inputarea-choose-btn ${
                      surveyData.currentlyInvested === "no" ? ` active` : ""
                    }`}
                    onClick={() =>
                      setSurveyData(old => ({
                        ...old,
                        currentlyInvested: "no",
                      }))
                    }
                  >
                    No
                  </button>
                </Flex>
              </Flex>

              {eventMeetingReq && (
                <Flex
                  direction="column"
                  className="attenderegisterationpopup-form-inputarea-choose"
                >
                  <span>
                    Would you be interested in having a meeting with{" "}
                    {hostedLabel}?
                  </span>
                  <Flex align="center" css={{ gap: "8px" }}>
                    <button
                      type="button"
                      className={`attenderegisterationpopup-form-inputarea-choose-btn
                      ${surveyData.interestedMeeting === "yes" ? ` active` : ""}
                    `}
                      onClick={() =>
                        setSurveyData(old => ({
                          ...old,
                          interestedMeeting: "yes",
                        }))
                      }
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className={`attenderegisterationpopup-form-inputarea-choose-btn ${
                        surveyData.interestedMeeting === "no" ? ` active` : ""
                      }`}
                      onClick={() =>
                        setSurveyData(old => ({
                          ...old,
                          interestedMeeting: "no",
                        }))
                      }
                    >
                      No
                    </button>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
        </div>
        <div className="eventsPopup-footer">
          <Button
            color="primary"
            variant="outlined"
            type="button"
            className="events6ix-btn events6ix-btn-primary"
            onClick={() => {
              saveSurvey();
            }}
          >
            &nbsp; SUBMIT &nbsp;
          </Button>
        </div>
      </div>
    </Flex>
  );
};
export default EventSurveypopup;
