import React from "react";
import { Link } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";
import { Button } from "@mui/material";

const LandingEnterprise = () => {
  return (
    <section className="events-page-landing-section events-page-landing-section-fill events-page-landing-section-space events-page-landing-enterprise">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-landing-section-header text-center">
          <h2 className="events-page-landing-section-header-heading">
            Enterprise Solution
          </h2>
          <div className="events-page-landing-section-header-text">
            Everything you need to maintain shareholder confidence
          </div>
        </div>

        <div className="events-page-landing-enterprise-content">
          <div className="events-page-landing-enterprise-content-item">
            <div className="events-page-landing-enterprise-content-item-title">
              Full-Service Events
            </div>
            <div className="events-page-landing-enterprise-content-item-text">
              Monthly video updates
            </div>
            <div className="events-page-landing-enterprise-content-item-text">
              Event management
            </div>
            <div className="events-page-landing-enterprise-content-item-text">
              Technical support
            </div>
          </div>
          <div className="events-page-landing-enterprise-content-item">
            <div className="events-page-landing-enterprise-content-item-title">
              Investor Reach
            </div>
            <div className="events-page-landing-enterprise-content-item-text">
              Investor network
            </div>
            <div className="events-page-landing-enterprise-content-item-text">
              Automated promotion
            </div>
            <div className="events-page-landing-enterprise-content-item-text">
              Recording distribution
            </div>
            <div className="events-page-landing-enterprise-content-item-text">
              Engagement analytics
            </div>
          </div>
        </div>

        <div className="events-page-landing-enterprise-content-action">
          <Button
            component={Link}
            to="https://meetings.6ix.com/bianca-pisciola/meet-with-bianca-pisciola"
            color="primary"
            variant="outlined"
            type="button"
            className="events6ix-btn events6ix-btn-primary"
            endIcon={<ArrowForward />}
          >
            Book a Call Now
          </Button>
        </div>
      </div>
    </section>
  );
};

export default LandingEnterprise;
