import React from "react";
import { Link } from "react-router-dom";
import { useMedia } from "react-use";
import { ArrowForward } from "@mui/icons-material";
import { Button } from "@mui/material";
import { config as cssConfig } from "@100mslive/roomkit-react";

const LandingSellOff = () => {
  const isMobile = useMedia(cssConfig.media.md);
  return (
    <section className="events-page-landing-section events-page-landing-section-space events-page-landing-selloff">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-landing-selloff-content">
          <div className="events-page-landing-selloff-content-label">
            <div className="events-page-landing-selloff-content-label-btn">
              PREVENT THE SILENT SELL-OFF
            </div>
          </div>
          <h1 className="events-page-landing-selloff-content-heading">
            Stop your shareholders from selling during quiet periods
          </h1>
          <div className="events-page-landing-selloff-content-text text-primary">
            Join 400+ public companies using monthly video updates to{" "}
            {!isMobile && <br />} maintain share price stability
          </div>
          <div className="events-page-landing-selloff-content-action">
            <Button
              component={Link}
              to="https://meetings.6ix.com/bianca-pisciola/meet-with-bianca-pisciola"
              color="primary"
              variant="outlined"
              type="button"
              className="events6ix-btn events6ix-btn-primary"
              endIcon={<ArrowForward />}
            >
              Book a 15-Minute Call
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingSellOff;
