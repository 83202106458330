import React from "react";
import { Link } from "react-router-dom";
import { useMedia } from "react-use";
import { ArrowForward } from "@mui/icons-material";
import { Button } from "@mui/material";
import { config as cssConfig } from "@100mslive/roomkit-react";

const LandingSellPeriod = () => {
  const isMobile = useMedia(cssConfig.media.md);
  return (
    <section className="events-page-landing-section events-page-landing-section-fill events-page-landing-section-space events-page-landing-enterprise">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-landing-section-header text-center">
          <h2 className="events-page-landing-section-header-heading">
            Stop your shareholders from selling {!isMobile && <br />} during
            quiet periods
          </h2>
          <div className="events-page-landing-section-header-text mt-20">
            Join 400+ public companies using monthly video updates to maintain
            share price stability
          </div>
        </div>

        <div className="events-page-landing-enterprise-content-action">
          <Button
            component={Link}
            to="https://meetings.6ix.com/bianca-pisciola/meet-with-bianca-pisciola"
            color="primary"
            variant="outlined"
            type="button"
            className="events6ix-btn events6ix-btn-primary"
            endIcon={<ArrowForward />}
          >
            Book a Call Now
          </Button>
        </div>
      </div>
    </section>
  );
};

export default LandingSellPeriod;
