import React from "react";

const LandingProven = () => {
  return (
    <section className="events-page-landing-section events-page-landing-section-space events-page-landing-proven">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-landing-section-header text-center">
          <h2 className="events-page-landing-section-header-heading">
            Proven Results
          </h2>
        </div>
        <div className="events-page-landing-proven-content">
          <div className="events-page-landing-proven-content-item">
            <div className="events-page-landing-proven-content-item-text">
              Reunion Gold
            </div>
            <div className="events-page-landing-proven-content-item-label">
              TSX:RGD
            </div>
            <div className="events-page-landing-proven-content-item-title">
              80% share price correlation with 6ix events
            </div>
          </div>
          <div className="events-page-landing-proven-content-item">
            <div className="events-page-landing-proven-content-item-text">
              Aldebaran Resources
            </div>
            <div className="events-page-landing-proven-content-item-label">
              TSX-V: ALDE
            </div>
            <div className="events-page-landing-proven-content-item-title">
              436% share price increase since starting monthly updates
            </div>
          </div>
          <div className="events-page-landing-proven-content-item">
            <div className="events-page-landing-proven-content-item-text">
              CoTec Holdings
            </div>
            <div className="events-page-landing-proven-content-item-label">
              TSX:CTH
            </div>
            <div className="events-page-landing-proven-content-item-title">
              397% market cap growth
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingProven;
