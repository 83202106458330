import React from "react";
export function MeetingIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1346_3048)">
        <path
          d="M4.9987 15.4167V2.5H19.1654V15.4167C19.1654 15.9692 18.9459 16.4991 18.5552 16.8898C18.1645 17.2805 17.6346 17.5 17.082 17.5H2.91536M13.332 5.83333H15.832V9.16667H13.332V5.83333ZM7.4987 12.5H16.6654H7.4987ZM7.4987 9.16667H10.832H7.4987ZM7.4987 5.83333H10.832H7.4987ZM4.9987 15.4167C4.9987 15.9692 4.7792 16.4991 4.3885 16.8898C3.9978 17.2805 3.4679 17.5 2.91536 17.5C2.36283 17.5 1.83293 17.2805 1.44223 16.8898C1.05152 16.4991 0.832031 15.9692 0.832031 15.4167V5.83333H5.01953L4.9987 15.4167Z"
          stroke="#8C8E95"
          strokeWidth="1.67"
        />
      </g>
      <defs>
        <clipPath id="clip0_1346_3048">
          <rect width="20" height="20" fill="#8C8E95" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default MeetingIcon;
