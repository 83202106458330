import React from "react";
import { Link } from "react-router-dom";
import { Box, Flex } from "@100mslive/roomkit-react";
import PasswordReset from "../components/Account/Password/PasswordReset";
import SEO from "../components/Common/SEO";
import { ArrowLeft } from "../icons";

export function PasswordResetPage() {
  return (
    <>
      <SEO title="Reset PIN | 6ix Events" />
      <Box css={{ flex: "1 1 0", height: "100%;", overflowY: "auto" }}>
        <Flex
          align="center"
          direction="column"
          className="eventsPopup eventsPopup-xs userLogin"
        >
          <Box
            className="eventsPopup-inner"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <div className="eventsPopup-header">
              <div className="eventsPopup-header-heading">
                Create New 4-digit PIN
              </div>
            </div>

            <div className="eventsPopup-content">
              <PasswordReset />

              <Flex className="userLogin-email-footer">
                <Link
                  to="/"
                  className="userLogin-email-link userLogin-email-footer-col"
                >
                  <ArrowLeft /> Go to Home
                </Link>
              </Flex>
            </div>
          </Box>
        </Flex>
      </Box>
    </>
  );
}
