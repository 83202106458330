import React from "react";
export function NumbersIcon(props) {
  return props.type === "1" ? (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#E3C050" />
      <path
        d="M15.9585 21.5V12.4531H15.8179L13.0522 14.375V12.25L15.9663 10.2266H18.3179V21.5H15.9585Z"
        fill="black"
      />
    </svg>
  ) : props.type === "2" ? (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#E3C050" />
      <path
        d="M12.1506 21.5V19.9141L15.7834 16.3281C17.3694 14.7891 17.7991 14.2188 17.7991 13.4297V13.4062C17.7991 12.4453 17.1428 11.7734 16.0647 11.7734C14.9631 11.7734 14.2053 12.5078 14.2053 13.5781V13.6172H12.0256L12.0178 13.5859C12.0178 11.4219 13.7053 9.9375 16.1741 9.9375C18.4944 9.9375 20.1194 11.2656 20.1194 13.2109V13.2344C20.1194 14.5156 19.4553 15.5781 17.4006 17.4766L15.3147 19.4219V19.6016H20.2834V21.5H12.1506Z"
        fill="black"
      />
    </svg>
  ) : props.type === "3" ? (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#E3C050" />
      <path
        d="M16.1516 21.75C13.6047 21.75 11.925 20.3828 11.7688 18.3672L11.761 18.2656H14.0188L14.0266 18.3516C14.1282 19.2266 14.9329 19.8516 16.1516 19.8516C17.3547 19.8516 18.1438 19.1797 18.1438 18.2266V18.2109C18.1438 17.1328 17.3391 16.5391 15.9719 16.5391H14.6829V14.8594H15.9407C17.1282 14.8594 17.8938 14.2344 17.8938 13.3203V13.3047C17.8938 12.3672 17.2454 11.7891 16.136 11.7891C15.0266 11.7891 14.2844 12.3906 14.1907 13.3281L14.1829 13.3984H12.011L12.0188 13.3047C12.1829 11.2891 13.7688 9.97656 16.136 9.97656C18.5579 9.97656 20.1204 11.1953 20.1204 12.9922V13.0078C20.1204 14.4141 19.0891 15.3281 17.7688 15.6016V15.6484C19.4485 15.8047 20.5422 16.7734 20.5422 18.3047V18.3203C20.5422 20.3672 18.7844 21.75 16.1516 21.75Z"
        fill="black"
      />
    </svg>
  ) : (
    <></>
  );
}
export default NumbersIcon;
