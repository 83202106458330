import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GppGoodOutlined, ShieldOutlined } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@mui/material";
import { Box, Flex, Input } from "@100mslive/roomkit-react";
import FullPageProgress from "../../../components/FullPageProgress";
import { ToastManager } from "../../../components/Toast/ToastManager";
import { useGlobalValue } from "../../../GlobalContext";
import {
  CheckMark,
  Colorchecked,
  Cross,
  Edit,
  PencilIcon,
  UploadFIle,
  UserIcon,
} from "../../../icons";
import ColorPicker from "../../Common/ColorPicker";
import ImageCropper from "../../Common/ImageCropper";
import PasswordProtect from "../Password/PasswordProtect";
import { SESSION_AUTH } from "../../../common/auth";
import { INVESTOR_TYPE_LIST } from "../../../common/utils";
import { USER_SERVICES } from "../../../services";
import "./style.scss";

export const Profile = () => {
  const { setIsUserReinitialize } = useGlobalValue();
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [cropProfileImage, setCropProfileImage] = useState("");
  const [cropCompanyLogo, setCropCompanyLogo] = useState("");

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState("");

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [work, setWork] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [bio, setBio] = useState("");
  const [investorType, setInvestorType] = useState("");

  const [companyLogoPreview, setCompanyLogoPreview] = useState("");
  const [profileImagePreview, setProfileImagePreview] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyLogoColor, setCompanyLogoColor] = useState(
    process.env.REACT_APP_COLOR_DEFAULT
  );
  const [profileImage, setProfileImage] = useState("");
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const continueUrl = queryParameters.get("continueUrl");
  const [dataFetched, setDataFetched] = useState(false);
  const [OtpStep, setOtpStep] = useState(0);
  const [verifyPhone, setVerifyPhone] = useState(0);
  const [phoneNumberOTP, setPhoneNumberOTP] = useState("");
  const [hasPassword, setHasPassword] = useState(false);
  const [pinRequest, setPinRequest] = useState(false);

  useEffect(() => {
    if (isUserSession && isUserSession?.isVerify) {
      setUserData(isUserSession);
    } else {
      setUserData("");
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);
    try {
      setLoading(true);
      USER_SERVICES.getProfile({
        userId: userData.userId,
        org: userData?.org?.id,
      })
        .then(data => {
          if (data.code === 200) {
            setFormValues(data.data);
            setEmail(data?.data?.email);
            setName(data?.data?.name || "");
            setJobTitle(data?.data?.jobTitle || "");
            setWork(data?.data?.work || "");
            setInvestorType(data?.data?.investorType || "");
            setBio(data?.data?.bio || "");
            setCompanyWebsite(data?.data?.companyWebsite || "");

            setProfileImage(data?.data?.profileImage || "");

            setCompanyLogo(data?.data?.companyLogo);
            if (data?.data?.companyLogoColor) {
              setCompanyLogoColor(data?.data?.companyLogoColor);
            }
            setVerifyPhone(data.data.verifyPhone || 0);
            setOtpStep(data.data.verifyPhone || 0);
            if (data.data.phoneNumber) {
              setPhoneNumber(data.data.phoneNumber);
            }
            setHasPassword(data?.data?.hasPassword);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
    return () => null;
  }, [userData, dataFetched, LOGOUT_USER_SESSION]);

  const saveProfile = async () => {
    if (!name) {
      ToastManager.addToast({ title: "Please fill your name" });
      return false;
    }

    if (!userData) {
      return;
    }
    setLoading(true);
    const profileData = new FormData();
    profileData.append("name", name?.trim());
    profileData.append("phoneNumber", formValues?.phoneNumber || "");
    profileData.append("verifyPhone", formValues?.verifyPhone || 0);
    profileData.append("jobTitle", jobTitle?.trim());
    profileData.append("work", work?.trim());
    profileData.append("companyWebsite", companyWebsite?.trim());
    profileData.append("investorType", investorType);
    profileData.append(
      "typicalSize",
      INVESTOR_TYPE_LIST?.[investorType]?.size || 0
    );
    profileData.append("bio", bio?.trim());
    profileData.append("companyLogoColor", companyLogoColor);
    if (companyLogo?.name) {
      profileData.append("companyLogo", companyLogo, companyLogo.name);
    }
    if (profileImage?.name) {
      profileData.append("profileImage", profileImage, profileImage.name);
    }
    profileData.append("userId", userData.userId);
    profileData.append("org", userData?.org?.id);
    try {
      USER_SERVICES.updateProfile(profileData)
        .then(data => {
          if (data.code === 200) {
            setIsUserReinitialize(new Date());
            if (continueUrl) {
              navigate(continueUrl);
            }
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          }
          if (data.message) {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(e => {
          ToastManager.addToast({ title: "Error Something went wrong" });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      ToastManager.addToast({ title: "Error Something went wrong" });
    }
  };

  useEffect(() => {
    if (companyLogo?.name) {
      const objectUrl = URL.createObjectURL(companyLogo);
      setCompanyLogoPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    } else {
      setCompanyLogoPreview(companyLogo);
    }
  }, [companyLogo]);

  const onSelectEventLogo = e => {
    if (!e?.target?.files || e?.target?.files?.length === 0) {
      setCompanyLogo(formValues?.companyLogo);
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setCropCompanyLogo(reader.result?.toString() || "")
    );
    reader.readAsDataURL(e.target.files[0]);
    e.target.value = "";
  };

  useEffect(() => {
    if (profileImage?.name) {
      const objectUrl = URL.createObjectURL(profileImage);
      setProfileImagePreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    } else {
      setProfileImagePreview(profileImage);
    }
  }, [profileImage]);

  const onSelectProfileImage = e => {
    if (!e?.target?.files || e?.target?.files?.length === 0) {
      setProfileImage(formValues?.profileImage);
      return;
    }
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setCropProfileImage(reader.result?.toString() || "")
    );
    reader.readAsDataURL(e.target.files[0]);
    e.target.value = "";
  };

  const verifyPhoneNumber = async () => {
    if (!phoneNumber) {
      ToastManager.addToast({ title: "Please fill your phone number" });
      return false;
    }
    //    setVerifyPhone(0);
    setLoading(true);
    let parma = {};
    parma["userId"] = userData.userId;
    parma["org"] = userData?.org?.id;
    parma["phoneNumber"] = phoneNumber;

    try {
      USER_SERVICES.verifyPhone(parma)
        .then(data => {
          if (data.code === 200) {
            setOtpStep(2);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          }
          if (data.message) {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(e => {
          ToastManager.addToast({ title: "Error Something went wrong" });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      ToastManager.addToast({ title: "Error Something went wrong" });
    }
  };
  const verifyPhoneNumberOTP = async () => {
    if (!phoneNumber) {
      setOtpStep(0);
      ToastManager.addToast({ title: "Please fill your phone number" });
      return false;
    }
    if (!phoneNumberOTP) {
      ToastManager.addToast({ title: "Please fill your OTP" });
      return false;
    }

    setLoading(true);
    let parma = { ...formValues };
    parma["userId"] = userData.userId;
    parma["org"] = userData?.org?.id;
    parma["phoneNumber"] = phoneNumber;
    parma["otp"] = phoneNumberOTP;

    try {
      USER_SERVICES.verifyPhoneOTP(parma)
        .then(data => {
          if (data.code === 200) {
            setVerifyPhone(1);
            setFormValues(old => ({
              ...old,
              phoneNumber: phoneNumber,
              verifyPhone: 1,
            }));

            setOtpStep(1);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          }
          if (data.message) {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(e => {
          ToastManager.addToast({ title: "Error Something went wrong" });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      ToastManager.addToast({ title: "Error Something went wrong" });
    }
  };
  const cancelEditPhone = () => {
    setOtpStep(1);
    setPhoneNumber(formValues.phoneNumber);
    setVerifyPhone(formValues.verifyPhone);
  };

  // const removePassword = () => {
  //   setLoading(true);
  //   USER_SERVICES.removePassword({
  //     userId: userData?.userId,
  //     org: userData?.org?.id,
  //   })
  //     .then(data => {
  //       if (data.code === 200) {
  //         // ToastManager.addToast({ title: data.message });
  //         // setDataFetched(false);
  //       } else if (data.code === 600) {
  //         LOGOUT_USER_SESSION();
  //       } else {
  //         ToastManager.addToast({ title: data.message });
  //       }
  //     })
  //     .catch(err => {
  //       ToastManager.addToast({
  //         title: err?.message,
  //       });
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  return (
    <React.Fragment>
      {loading && <FullPageProgress fixed={true} />}
      {formValues ? (
        <Box className="events-page-profile-inner">
          <form
            name="userprofileform"
            id="userprofileform"
            className="custom-form"
          >
            <label
              htmlFor="profile-choose"
              className="events-page-profile-profileImg"
              translate="no"
            >
              <div className="events-page-profile-profileImg-profileicon">
                <Input
                  type="file"
                  id="profile-choose"
                  accept="image/x-png,image/gif,image/jpeg,image/jpg,image/webp"
                  className="custom-form-file-input"
                  onChange={onSelectProfileImage}
                />
                {profileImagePreview ? (
                  <img src={profileImagePreview} alt="Profile" />
                ) : (
                  <UserIcon />
                )}
              </div>
              <span translate="yes">
                <Edit /> Edit photo
              </span>
            </label>

            <FormControl className="custom-form-group">
              <label htmlFor="email" className="custom-form-label big-label">
                Email
              </label>
              <Input
                id="email"
                name="email"
                disabled
                placeholder="Enter Your EMail"
                className="custom-form-input"
                value={email}
                autoComplete="none"
              />
            </FormControl>
            <FormControl className="custom-form-group">
              <label htmlFor="name" className="custom-form-label big-label">
                Name
              </label>
              <Input
                id="name"
                name="name"
                placeholder="Enter Your Name"
                className="custom-form-input"
                value={name}
                onChange={e => setName(e.target.value)}
                autoComplete="none"
              />
            </FormControl>
            {OtpStep === 1 ? (
              <>
                <FormControl className="custom-form-group">
                  <label
                    htmlFor="phoneNumber"
                    className="custom-form-label big-label"
                  >
                    Phone Number
                  </label>
                  <Flex className="custom-form-input-row">
                    <Input
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder="Phone number? (+1xxxxxxxxxx)"
                      className="custom-form-input"
                      value={phoneNumber}
                      disabled
                    />
                    <span className="custom-form-input-additional">
                      <IconButton size="small" onClick={() => setOtpStep(0)}>
                        <PencilIcon />
                      </IconButton>
                      {verifyPhone ? <Colorchecked /> : null}
                    </span>
                  </Flex>
                </FormControl>
              </>
            ) : OtpStep === 2 ? (
              <FormControl className="custom-form-group">
                <label
                  htmlFor="phoneNumberOTP"
                  className="custom-form-label big-label"
                >
                  Enter OTP received in Phone (<small>{phoneNumber}</small>)
                </label>
                <Flex className="custom-form-input-row">
                  <Input
                    id="phoneNumberOTP"
                    name="phoneNumberOTP"
                    placeholder="OTP 1234"
                    className="custom-form-input"
                    value={phoneNumberOTP}
                    onChange={e => {
                      setPhoneNumberOTP(e.target.value);
                    }}
                  />
                  <span className="custom-form-input-additional">
                    <Button
                      disabled={!phoneNumber}
                      color="primary"
                      size="small"
                      variant="outlined"
                      className="btn btn-primary"
                      type="button"
                      onClick={verifyPhoneNumberOTP}
                    >
                      VERIFY
                    </Button>
                  </span>
                </Flex>
                <Flex className="custom-form-label big-label mt-5" gap="5px">
                  <span>Don’t receive code?</span>
                  <Button
                    color="primary"
                    size="small"
                    variant="text"
                    type="button"
                    onClick={verifyPhoneNumber}
                  >
                    Resend OTP
                  </Button>
                  <span>or</span>
                  <Button
                    color="primary"
                    size="small"
                    variant="text"
                    type="button"
                    onClick={() => setOtpStep(0)}
                  >
                    Change number
                  </Button>
                </Flex>
              </FormControl>
            ) : (
              <FormControl className="custom-form-group">
                <label
                  htmlFor="phoneNumber"
                  className="custom-form-label big-label"
                >
                  Phone Number
                </label>
                <Flex className="custom-form-input-row">
                  <Input
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Phone number? (+1xxxxxxxxxx)"
                    className="custom-form-input"
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                  />
                  <span className="custom-form-input-additional">
                    {verifyPhone ? (
                      <IconButton size="small" onClick={cancelEditPhone}>
                        <Cross white={true} />
                      </IconButton>
                    ) : null}
                    <Button
                      disabled={
                        !phoneNumber ||
                        (verifyPhone && phoneNumber === formValues?.phoneNumber)
                      }
                      color="primary"
                      size="small"
                      variant="outlined"
                      className="btn btn-primary"
                      type="button"
                      onClick={verifyPhoneNumber}
                    >
                      VERIFY
                    </Button>
                  </span>
                </Flex>
              </FormControl>
            )}

            <FormControl className="custom-form-group">
              <label htmlFor="jobTitle" className="custom-form-label big-label">
                Job title
              </label>
              <Input
                id="jobTitle"
                name="jobTitle"
                placeholder="Job title"
                className="custom-form-input"
                value={jobTitle}
                onChange={e => setJobTitle(e.target.value)}
              />
            </FormControl>
            <FormControl className="custom-form-group">
              <label
                htmlFor="company-name"
                className="custom-form-label big-label"
              >
                Company
              </label>
              <Input
                id="company-name"
                name="work"
                placeholder="Company"
                className="custom-form-input"
                value={work}
                onChange={e => setWork(e.target.value)}
              />
            </FormControl>

            <FormControl className="custom-form-group">
              <label
                htmlFor="event-investorType"
                className="custom-form-label big-label"
              >
                Investor Type
              </label>

              <Select
                id="event-investorType"
                name="investorType"
                className="custom-select"
                sx={{
                  height: 45,
                  background: "#131313",
                  borderRadius: "8px",
                }}
                value={investorType}
                onChange={e => {
                  setInvestorType(e.target.value);
                }}
                displayEmpty
              >
                <MenuItem disabled value="">
                  <span>Select</span>
                </MenuItem>
                {Object.keys(INVESTOR_TYPE_LIST).map((item, index) => (
                  <MenuItem key={index} value={INVESTOR_TYPE_LIST[item].value}>
                    {INVESTOR_TYPE_LIST[item].name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className="custom-form-group">
              <label htmlFor="user-bio" className="custom-form-label big-label">
                Bio (Optional)
              </label>
              <TextareaAutosize
                id="user-bio"
                name="bio"
                value={bio}
                onChange={e => setBio(e.target.value)}
                className="custom-form-input"
                placeholder="Enter Bio"
                minRows={2}
                maxRows={3}
              />
            </FormControl>

            <FormControl className="custom-form-group">
              <label
                htmlFor="company-Website"
                className="custom-form-label big-label"
              >
                Company Website (Optional)
              </label>
              <Input
                id="company-Website"
                name="companyWebsite"
                placeholder="Company Website"
                className="custom-form-input"
                value={companyWebsite}
                onChange={e => setCompanyWebsite(e.target?.value)}
              />
            </FormControl>

            <FormControl className="custom-form-group mt-10">
              <label className="custom-form-label big-label events-page-profile-pin-label">
                {hasPassword ? (
                  <>
                    <CheckMark />
                    Your account is protected by a PIN code
                  </>
                ) : (
                  <>Protect your account with a PIN code</>
                )}
              </label>
              <div className="mb-10 mt-10">
                <Button
                  color="secondary"
                  variant="outlined"
                  className="events6ix-btn events6ix-btn-secondary "
                  type="button"
                  startIcon={
                    hasPassword ? <GppGoodOutlined /> : <ShieldOutlined />
                  }
                  onClick={() => setPinRequest(true)}
                >
                  {hasPassword ? "Change PIN" : "Add PIN"}
                </Button>
              </div>
            </FormControl>

            <FormControl className="custom-form-group">
              <label className="custom-form-label big-label">
                Company Logo
              </label>
              <Flex align="center" css={{ gap: "15px" }} translate="no">
                <label
                  htmlFor="company-logo"
                  className="custom-form-file mt-10"
                  translate="no"
                >
                  <Input
                    type="file"
                    id="company-logo"
                    accept="image/x-png,image/gif,image/jpeg,image/jpg,image/webp"
                    className="custom-form-file-input"
                    onChange={onSelectEventLogo}
                  />

                  {companyLogoPreview ? (
                    <>
                      <div
                        className="custom-form-file-preview"
                        style={{ backgroundColor: companyLogoColor }}
                      >
                        <img src={companyLogoPreview} alt="company logo" />
                      </div>
                      <Edit />
                    </>
                  ) : (
                    <>
                      <UploadFIle /> <span translate="yes"> Upload</span>
                    </>
                  )}
                </label>
                {companyLogoPreview ? (
                  <ColorPicker
                    title="Logo Background Color"
                    selectedColor={companyLogoColor}
                    setSelectedColor={setCompanyLogoColor}
                  />
                ) : null}
              </Flex>
            </FormControl>
          </form>
          <div className="text-center">
            <Button
              color="primary"
              size="small"
              variant="outlined"
              className="events6ix-btn events6ix-btn-primary events6ix-btn-md events-page-profile-btn"
              type="button"
              onClick={() => {
                saveProfile();
              }}
            >
              Save
            </Button>
          </div>
        </Box>
      ) : null}
      {cropProfileImage && (
        <ImageCropper
          open={true}
          data={{
            image: cropProfileImage,
            title: "Profile image",
            minWidth: 80,
            minHeight: 80,
            ratio: 1 / 1,
            feature: ["circle", "square", "freedom"],
          }}
          onCancel={() => {
            setCropProfileImage("");
            onSelectProfileImage();
          }}
          onUpdate={image => {
            setProfileImage(image);
            setCropProfileImage("");
          }}
        />
      )}
      {cropCompanyLogo && (
        <ImageCropper
          open={true}
          data={{
            image: cropCompanyLogo,
            title: "Company logo",
            minWidth: 60,
            minHeight: 60,
            ratio: 1 / 1,
            feature: ["square", "freedom"],
          }}
          onCancel={() => {
            setCropCompanyLogo("");
            onSelectEventLogo();
          }}
          onUpdate={image => {
            setCompanyLogo(image);
            setCropCompanyLogo("");
          }}
        />
      )}

      {pinRequest && (
        <PasswordProtect
          hasPassword={hasPassword}
          onClose={() => {
            setDataFetched(false);
            setPinRequest(false);
          }}
        />
      )}
    </React.Fragment>
  );
};
