import React, { useEffect, useState } from "react";
import { Box, Flex } from "@100mslive/roomkit-react";
import { Cross } from "../../../icons";
import ForgotPassword from "./ForgotPassword";
import LoginWithEmail from "./LoginWithEmail";
import LoginWithMagic from "./LoginWithMagic";
import LoginWithPassword from "./LoginWithPassword";
import { SESSION_AUTH } from "../../../common/auth";
import "./Login.scss";
export function LoginPopup(props) {
  const { isUserSession } = SESSION_AUTH();
  const { handleLogin, dropOut, cancel } = props;
  const [passwordLogin, setPasswordLogin] = useState(0);
  const [email, setEmail] = useState("");
  useEffect(() => {
    if (isUserSession) {
      handleLogin();
    }
  }, [isUserSession, handleLogin]);

  return (
    <Flex
      align="center"
      direction="column"
      className="eventsPopup eventsPopup-xs userLogin"
      onClick={() => {
        if (dropOut) {
          handleLogin();
        }
      }}
    >
      <Box
        className="eventsPopup-inner"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {cancel && (
          <div className="eventsPopup-close" onClick={handleLogin}>
            <Cross />
          </div>
        )}

        {email && passwordLogin === 1 ? (
          <>
            <div className="eventsPopup-header">
              <div className="eventsPopup-header-heading">
                Continue with Email
              </div>
              <div className="eventsPopup-header-desc"></div>
            </div>
            <div className="eventsPopup-content">
              <LoginWithMagic
                email={email}
                setEmail={setEmail}
                handleLogin={handleLogin}
                setPasswordLogin={setPasswordLogin}
              />
            </div>
          </>
        ) : email && passwordLogin === 2 ? (
          <>
            <div className="eventsPopup-header">
              <div className="eventsPopup-header-heading">
                Enter your 4-digit PIN
              </div>
              <div className="eventsPopup-header-desc"></div>
            </div>
            <div className="eventsPopup-content">
              <LoginWithPassword
                setPasswordLogin={setPasswordLogin}
                handleLogin={handleLogin}
                setEmail={setEmail}
                email={email}
              />
            </div>
          </>
        ) : email && (passwordLogin === 3 || passwordLogin === 4) ? (
          <>
            <div className="eventsPopup-header">
              <div className="eventsPopup-header-heading">Reset PIN</div>
              <div className="eventsPopup-header-desc">
                {passwordLogin === 3 ? (
                  <> We will send you a link to reset your PIN </>
                ) : null}
              </div>
              <div className="eventsPopup-header-desc"></div>
            </div>
            <div className="eventsPopup-content">
              <ForgotPassword
                passwordLogin={passwordLogin}
                email={email}
                setEmail={setEmail}
                setPasswordLogin={setPasswordLogin}
              />
            </div>
          </>
        ) : (
          <>
            <div className="eventsPopup-header">
              <div className="eventsPopup-header-heading">Enter your email</div>
              <div className="eventsPopup-header-desc"></div>
            </div>
            <div className="eventsPopup-content">
              <LoginWithEmail
                setPasswordLogin={setPasswordLogin}
                handleLogin={handleLogin}
                setEmail={setEmail}
                email={email}
              />
            </div>
          </>
        )}
      </Box>
    </Flex>
  );
}
