import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FormControl } from "@mui/material";
import { Input, Label } from "@100mslive/roomkit-react";
import { auth } from "../../../firebase";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { useAuthState } from "react-firebase-hooks/auth";
import { SESSION_AUTH } from "../../../common/auth";
import { isValidEmail } from "../../../common/utils";
import { USER_SERVICES } from "../../../services";

const LoginWithEmail = props => {
  const { SET_USER_SESSION } = SESSION_AUTH();
  const { handleLogin, setPasswordLogin, email, setEmail } = props;
  const [, loading, error] = useAuthState(auth);
  const [loader, setLoader] = useState(false);

  const loginEmail = async () => {
    if (!email || (email && !isValidEmail(email))) {
      ToastManager.addToast({ title: "Enter your email address" });
    } else {
      setLoader(true);
      USER_SERVICES.loginEmail({ email: email })
        .then(data => {
          if (data?.code === 201) {
            setPasswordLogin(2);
          } else if (data?.code === 200) {
            SET_USER_SESSION(data?.data);
            if (handleLogin) handleLogin();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => setLoader(false));
    }
  };

  useEffect(() => {
    if (error) {
      ToastManager.addToast({ title: error });
    }
  }, [error]);
  return (
    <>
      {(loading || loader) && <FullPageProgress fixed={true} />}

      <div className="userLogin-email custom-form">
        <FormControl className="custom-form-group">
          <Label className="custom-form-label" htmlFor="login-email">
            Email
          </Label>
          <Input
            id="login-email"
            placeholder="your@email.com"
            className="custom-form-input"
            type="email"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
            name="email"
          />
        </FormControl>
        <button
          type="button"
          className="userLogin-email-btn mt-20"
          onClick={loginEmail}
          disabled={loader || loading}
        >
          Continue
        </button>

        <div className="userLogin-email-policy mt-20">
          Clicking continue confirms you accept 6ix's{" "}
          <Link to="/privacy-policy" className="text-link" target="_blank">
            Privacy Notice.
          </Link>
        </div>
      </div>
    </>
  );
};
export default LoginWithEmail;
