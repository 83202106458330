import React, { useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Flex } from "@100mslive/roomkit-react";
import { BuildingIcon, MeetingIcon, PersonsIcon } from "../../icons";
import { ToastManager } from "../Toast/ToastManager";
import { EXPLORE_SERVICES } from "../../services";

const ExploreStatics = () => {
  const [totalMeeting, setTotalMeeting] = useState("");
  const [totalOrg, setTotalOrg] = useState("");
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    EXPLORE_SERVICES.statics()
      .then(data => {
        if (data.code === 200) {
          setTotalMeeting(data.data?.meeting || 0);
          setTotalOrg(data.data?.organization || 0);
        }
      })
      .catch(err => {
        ToastManager.addToast({ title: err.message });
      });
  }, [dataFetchedRef]);

  return (
    <Flex className="event-page-explore-statics">
      <Flex className="event-page-explore-statics-col">
        <Flex className="event-page-explore-statics-heading">
          <PersonsIcon /> &nbsp;
          <NumericFormat
            value={114332}
            displayType="text"
            thousandSeparator={true}
            renderText={(value, props) => <span {...props}>{value}</span>}
          />
        </Flex>
        <div className="event-page-explore-statics-text">
          Registered Investors
        </div>
      </Flex>
      {totalOrg ? (
        <Flex className="event-page-explore-statics-col">
          <Flex className="event-page-explore-statics-heading">
            <BuildingIcon />
            &nbsp;
            <NumericFormat
              value={totalOrg}
              displayType="text"
              thousandSeparator={true}
              renderText={(value, props) => <span {...props}>{value}</span>}
            />
          </Flex>
          <div className="event-page-explore-statics-text">Organizations</div>
        </Flex>
      ) : null}
      {totalMeeting ? (
        <Flex className="event-page-explore-statics-col">
          <Flex className="event-page-explore-statics-heading">
            <MeetingIcon />
            &nbsp;
            <NumericFormat
              value={totalMeeting}
              displayType="text"
              thousandSeparator={true}
              renderText={(value, props) => <span {...props}>{value}</span>}
            />
          </Flex>
          <div className="event-page-explore-statics-text">Meetings</div>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default ExploreStatics;
