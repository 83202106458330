import React from "react";
import SEO from "../../components/Common/SEO";
import EventFooter from "../../components/Event/EventFooter";
import LandingEnterprise from "../../components/Landing/LandingEnterprise";
import LandingHowItWork from "../../components/Landing/LandingHowItWork";
import LandingProven from "../../components/Landing/LandingProven";
import LandingScore from "../../components/Landing/LandingScore";
import LandingSellOff from "../../components/Landing/LandingSellOff";
import LandingSellPeriod from "../../components/Landing/LandingSellPeriod";
import "./landing.scss";

const HomePage = () => {
  return (
    <React.Fragment>
      <SEO title="Home | 6ix Events" />

      <main className="events-page-landing events6ix-layout-page" role="main">
        <div className="events6ix-layout-page-content no-space">
          <LandingSellOff />
          <LandingScore />
          <LandingHowItWork />
          <LandingEnterprise />
          <LandingProven />
          <LandingSellPeriod />
        </div>
      </main>
      <EventFooter />
    </React.Fragment>
  );
};

export default HomePage;
