import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Profile } from "../components/Account/Profile/Profile";
import SEO from "../components/Common/SEO";
import EventFooter from "../components/Event/EventFooter";
import { useGlobalValue } from "../GlobalContext";
import { SESSION_AUTH } from "../common/auth";

export function ProfilePage() {
  const { isUserSession } = SESSION_AUTH();
  const navigate = useNavigate();
  const { setLoginUserVerify } = useGlobalValue();

  useEffect(() => {
    if (!isUserSession) {
      return navigate("/");
    } else if (!isUserSession?.isVerify) {
      setLoginUserVerify(true);
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate, setLoginUserVerify]);
  return (
    <React.Fragment>
      <SEO title="Profile | 6ix Events" />
      <main className="events-page-profile events6ix-layout-page" role="main">
        <div className="events6ix-layout-page-content events6ix-layout-page-content-space-top">
          <Profile />
        </div>
      </main>
      <EventFooter />
    </React.Fragment>
  );
}
