import React, { useState } from "react";
import { FormControl } from "@mui/material";
import { Box, Flex } from "@100mslive/roomkit-react";
import { Cross } from "../../../icons";
import PinBox from "../../Common/PinBox";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { SESSION_AUTH } from "../../../common/auth";
import { USER_SERVICES } from "../../../services";

const PasswordProtect = props => {
  const { onClose, hasPassword } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [loader, setLoader] = useState(false);
  const [pin, setPin] = useState("");

  const savePassword = async () => {
    if (!pin) {
      ToastManager.addToast({ title: "Enter new PIN" });
    } else if (pin && pin?.length < 4) {
      ToastManager.addToast({
        title: "Enter 4-digit PIN",
      });
    } else {
      setLoader(true);
      USER_SERVICES.updatePassword({
        userId: isUserSession.userId,
        org: isUserSession?.org?.id,
        password: pin,
      })
        .then(data => {
          if (data.code === 200) {
            ToastManager.addToast({ title: data.message });
            return onClose();
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(err => {
          ToastManager.addToast({
            title: err?.message,
          });
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  return (
    <Flex
      align="center"
      direction="column"
      className="eventsPopup eventsPopup-xs userLogin"
    >
      {loader ? (
        <FullPageProgress fixed={true} />
      ) : (
        <Box
          className="eventsPopup-inner"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <div className="eventsPopup-close" onClick={onClose}>
            <Cross />
          </div>

          <div className="eventsPopup-header">
            <div className="eventsPopup-header-heading">
              {hasPassword ? "Change 4-digit PIN" : "Create New 4-digit PIN"}
            </div>
          </div>

          <div className="eventsPopup-content">
            <div className="userLogin-email custom-form mt-20">
              <FormControl className="custom-form-group">
                <PinBox updatePin={setPin} />
              </FormControl>
              <button
                type="button"
                className="userLogin-email-btn mt-20"
                onClick={savePassword}
                disabled={loader}
              >
                Submit
              </button>
            </div>
          </div>
        </Box>
      )}
    </Flex>
  );
};

export default PasswordProtect;
