import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormControl } from "@mui/material";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { auth, signInWithEmailPassword } from "../../../firebase";
import PinBox from "../../Common/PinBox";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { useAuthState } from "react-firebase-hooks/auth";
import { SESSION_AUTH } from "../../../common/auth";

const PasswordReset = () => {
  const { isUserSession, SET_USER_SESSION } = SESSION_AUTH();
  const [, loading, error] = useAuthState(auth);
  const [loader, setLoader] = useState(true);
  const [email, setEmail] = useState("");
  const [continueUrl, setContinueUrl] = useState("");
  const { actionCode } = useParams();
  const navigate = useNavigate();
  const dataFetched = useRef(null);
  const [pin, setPin] = useState("");

  useEffect(() => {
    if (isUserSession && isUserSession?.isVerify) {
      navigate("/");
    }
  }, [isUserSession, navigate]);

  const savePassword = async () => {
    if (!pin) {
      ToastManager.addToast({ title: "Enter new PIN" });
    } else if (pin && pin?.length < 4) {
      ToastManager.addToast({
        title: "Enter 4-digit PIN",
      });
    } else {
      setLoader(true);

      confirmPasswordReset(auth, actionCode, email?.slice(0, 2) + pin)
        .then(async () => {
          const data = await signInWithEmailPassword(email, pin, true);
          if (data?.code === 200 && data?.data) {
            SET_USER_SESSION(data?.data);
            navigate(continueUrl || "/");
          } else {
            navigate(continueUrl || "/");
          }
          setLoader(false);
        })
        .catch(error => {
          ToastManager.addToast({
            title: error?.message,
          });
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    if (error) {
      ToastManager.addToast({ title: error });
    }
  }, [error]);

  useEffect(() => {
    if (actionCode && !loading && !dataFetched.current) {
      dataFetched.current = true;
      const search = window.location.search;
      const params = new URLSearchParams(search);

      if (params.get("continueUrl")) {
        setContinueUrl(params.get("continueUrl"));
      }
      const confirmEmail = params.get("email");

      verifyPasswordResetCode(auth, actionCode)
        .then(email => {
          if (confirmEmail === email) {
            setEmail(email);
          } else {
            ToastManager.addToast({ title: "Email mismatch" });
            navigate("/");
          }
          setLoader(false);
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
          navigate("/");
        });
    }
    return () => null;
  }, [actionCode, loading, navigate]);
  return (
    <>
      {loading || loader ? (
        <FullPageProgress fixed={true} />
      ) : email ? (
        <div className="userLogin-email custom-form mt-20">
          <FormControl className="custom-form-group">
            <PinBox updatePin={setPin} />
          </FormControl>

          <button
            type="button"
            className="userLogin-email-btn mt-20"
            onClick={savePassword}
            disabled={loader || loading}
          >
            Continue
          </button>
        </div>
      ) : null}
    </>
  );
};
export default PasswordReset;
