import React, { useState } from "react";
import { IconButton, MenuItem, Select, Tooltip } from "@mui/material";
import { Box, Flex, Input, Label, Switch } from "@100mslive/roomkit-react";
import { InfoIcon, MicIcon, VideoIcon } from "../../icons";
import ConfirmDialog from "../Common/ConfirmDialog";
import {
  EVENT_LEVEL,
  EVENT_STATUS,
  EVENT_TYPE,
  ROOM_TYPE,
} from "../../common/constants";

const EventOptions = props => {
  const {
    userData,
    roomType,
    setRoomType,
    eventType,
    setEventType,
    eventLevel,
    setEventLevel,
    disableSurvey,
    setDisableSurvey,
    eventChatQA,
    setEventChatQA,
    currentStep,
    setEventCalendlyLink,
    eventCalendlyLink,
    eventCalendlyLinkAdd,
    setEventCalendlyLinkAdd,
    eventMeetingReq,
    setEventMeetingReq,
    orgAccountList,
    eventStatus,
  } = props;
  const [confirmRoom, setConfirmRoom] = useState("");
  const [selectCalOrg, setSelectCalOrg] = useState("");

  const onChangeRoomType = item => {
    if (item.confirm) {
      setConfirmRoom(item.value);
    } else {
      setRoomType(item.value);
    }
  };

  const onAcceptRoomType = () => {
    setRoomType(confirmRoom);
    setConfirmRoom("");
  };

  const changeOrgCal = value => {
    setSelectCalOrg(value);
    if (value === "custom") {
      setEventCalendlyLink("");
    } else {
      setEventCalendlyLink(value);
    }
  };
  return (
    <>
      <div className="home-page-layout-content-option">
        <Flex
          direction="column"
          css={{
            py: "$4",
            w: "100%",
            gap: "$2",
          }}
        >
          <Label className="home-page-layout-content-option-label">
            Visibility
            <Tooltip
              placement="top"
              arrow
              title="Visibility: The event will be listed in the explore page if the event is public"
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Label>
          <div className="eventsToggleGroup">
            {Object.keys(EVENT_TYPE)?.map((item, key) => {
              return (
                <div
                  key={key}
                  className={`eventsToggleGroup-btn ${
                    eventType === EVENT_TYPE?.[item]?.value ? "active" : ""
                  }`}
                  onClick={() => {
                    if (
                      currentStep === "event" &&
                      eventStatus !== EVENT_STATUS?.ended?.value
                    ) {
                      setEventType(EVENT_TYPE?.[item]?.value);
                    }
                  }}
                >
                  {EVENT_TYPE?.[item]?.name}
                </div>
              );
            })}
          </div>
        </Flex>
        <Flex
          direction="column"
          css={{
            py: "$4",
            w: "100%",
            gap: "$2",
          }}
        >
          <Label className="home-page-layout-content-option-label">
            Event Type
            <Tooltip
              placement="top"
              arrow
              title="Event Type: Choose Webinar for a scheduled live event or Meeting to conduct anytime without a set date or time"
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Label>
          <div className="eventsToggleGroup">
            {Object.keys(EVENT_LEVEL)?.map((item, key) => {
              return (
                <div
                  key={key}
                  className={`eventsToggleGroup-btn ${
                    eventLevel === EVENT_LEVEL?.[item]?.value ? "active" : ""
                  }`}
                  onClick={() => {
                    if (
                      currentStep === "event" &&
                      eventStatus !== EVENT_STATUS?.ended?.value
                    ) {
                      setEventLevel(EVENT_LEVEL?.[item]?.value);
                    }
                  }}
                >
                  {EVENT_LEVEL?.[item]?.name}
                </div>
              );
            })}
          </div>
        </Flex>
        {userData ? (
          <Flex
            direction="column"
            css={{
              py: "$4",
              w: "100%",
              gap: "$2",
            }}
          >
            <Label className="home-page-layout-content-option-label">
              Room Type
              <Tooltip
                placement="top"
                arrow
                title="Select the number of people the room can accommodate"
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Label>
            <div className="eventsToggleGroup">
              {Object.keys(ROOM_TYPE)?.map((item, key) => {
                return (
                  <Tooltip
                    key={key}
                    placement="top"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#000",
                          border: "1px solid #2B2B2B",
                        },
                      },
                    }}
                    title={
                      <Box className="eventsToggleGroup-btn-room-tooltip">
                        <Flex className="eventsToggleGroup-btn-room-tooltip-title">
                          {ROOM_TYPE?.[item]?.text}
                        </Flex>
                        <Flex className="eventsToggleGroup-btn-room-tooltip-option">
                          <MicIcon on={ROOM_TYPE?.[item]?.attendes?.mic} /> Mics
                        </Flex>
                        <Flex className="eventsToggleGroup-btn-room-tooltip-option">
                          <VideoIcon on={ROOM_TYPE?.[item]?.attendes?.mic} />{" "}
                          Cameras
                        </Flex>
                      </Box>
                    }
                  >
                    <div
                      className={`eventsToggleGroup-btn  ${
                        roomType === ROOM_TYPE?.[item]?.value ? "active" : ""
                      }`}
                      onClick={() => {
                        if (
                          currentStep === "event" &&
                          ROOM_TYPE?.[item]?.value !== roomType
                        ) {
                          onChangeRoomType(ROOM_TYPE?.[item]);
                        }
                      }}
                    >
                      {ROOM_TYPE?.[item]?.name}
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </Flex>
        ) : null}
        <Flex
          direction="column"
          css={{
            py: "$4",
            gap: "$2",
          }}
        >
          <Label className="home-page-layout-content-option-label">
            Survey questionnaire for attendees
            <Tooltip
              placement="top"
              arrow
              title="Survey Questions: If enabled, we will ask investors various questions about themselves such as investor type, shareholder status, etc."
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Label>
          <div className="eventsToggleGroup">
            <div
              className={`eventsToggleGroup-btn ${
                !disableSurvey ? "active" : ""
              }`}
              onClick={() => {
                if (
                  currentStep === "event" &&
                  eventStatus !== EVENT_STATUS?.ended?.value
                ) {
                  setDisableSurvey(false);
                }
              }}
            >
              Enabled
            </div>
            <div
              className={`eventsToggleGroup-btn ${
                disableSurvey ? "active" : ""
              }`}
              onClick={() => {
                if (
                  currentStep === "event" &&
                  eventStatus !== EVENT_STATUS?.ended?.value
                ) {
                  setDisableSurvey(true);
                  setEventMeetingReq(false);
                }
              }}
            >
              Disabled
            </div>
          </div>
          {!disableSurvey && (
            <Label
              className="home-page-layout-content-option-label"
              css={{
                cursor: "pointer",
                gap: "$8",
                mt: "$8",
              }}
            >
              <Switch
                checked={eventMeetingReq}
                onCheckedChange={() => {
                  if (
                    currentStep === "event" &&
                    eventStatus !== EVENT_STATUS?.ended?.value
                  ) {
                    setEventMeetingReq(!eventMeetingReq);
                  }
                }}
              />
              Meeting Request{" "}
              <Tooltip
                placement="top"
                arrow
                title="Meeting Request: Enabling this allows users to select the option during event registration; disabling it hides the option."
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Label>
          )}
        </Flex>
        {userData ? (
          <>
            <Flex
              direction="column"
              css={{
                mt: "$2",
                py: "$4",
                w: "100%",
                gap: "$2",
                alignItems: "flex-start",
              }}
            >
              <Label className="home-page-layout-content-option-label">
                Chat
                <Tooltip
                  placement="top"
                  arrow
                  title="Chat: If enabled, users will be able to use the live chat inside the event"
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Label>

              <div className="eventsToggleGroup">
                <div
                  className={`eventsToggleGroup-btn ${
                    eventChatQA ? "active" : ""
                  }`}
                  onClick={() => {
                    if (
                      currentStep === "event" &&
                      eventStatus !== EVENT_STATUS?.ended?.value
                    ) {
                      setEventChatQA(true);
                    }
                  }}
                >
                  Enabled
                </div>
                <div
                  className={`eventsToggleGroup-btn ${
                    !eventChatQA ? "active" : ""
                  }`}
                  onClick={() => {
                    if (
                      currentStep === "event" &&
                      eventStatus !== EVENT_STATUS?.ended?.value
                    ) {
                      setEventChatQA(false);
                    }
                  }}
                >
                  Disabled
                </div>
              </div>
            </Flex>

            <Flex
              direction="column"
              css={{
                mt: "$2",
                py: "$4",
                w: "100%",
                gap: "$8",
                alignItems: "flex-start",
              }}
            >
              <Label
                className="home-page-layout-content-option-label"
                css={{
                  cursor: "pointer",
                  gap: "$8",
                }}
              >
                <Switch
                  checked={eventCalendlyLinkAdd}
                  onCheckedChange={() => {
                    if (
                      currentStep === "event" &&
                      eventStatus !== EVENT_STATUS?.ended?.value
                    ) {
                      setEventCalendlyLinkAdd(!eventCalendlyLinkAdd);
                    }
                  }}
                />
                Add Calendar Link
                <Tooltip
                  placement="top"
                  arrow
                  title="Add Calendar: Select whether you want to include your calendar link so attendees can optionally book a meeting with you"
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Label>
              {eventCalendlyLinkAdd ? (
                <Flex
                  //direction="column"
                  css={{
                    w: "100%",
                    gap: "$8",
                  }}
                >
                  {orgAccountList && (
                    <Box
                      css={{
                        minWidth: "50%",
                      }}
                    >
                      <label
                        htmlFor="event-calendar-org"
                        className="custom-form-label"
                      >
                        Organization calendar
                      </label>
                      <Select
                        id="event-calendar-org"
                        name="eventcalendarorg"
                        className="custom-select w-100"
                        onChange={e => {
                          changeOrgCal(e.target.value);
                        }}
                        value={selectCalOrg}
                        displayEmpty
                        readOnly={currentStep !== "event"}
                        placeholder="Select"
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>
                        {orgAccountList
                          .filter(item => item?.orgCalLink)
                          .map((item, key) => {
                            return (
                              <MenuItem key={key} value={item?.orgCalLink}>
                                {item?.orgName}
                              </MenuItem>
                            );
                          })}
                        <MenuItem value="custom">Custom</MenuItem>
                      </Select>
                    </Box>
                  )}
                  {selectCalOrg || !orgAccountList ? (
                    <Box
                      css={{
                        minWidth: "50%",
                      }}
                    >
                      <label
                        htmlFor="event-calendar-org-url"
                        className="custom-form-label"
                      >
                        URL
                      </label>
                      <Input
                        id="event-calendar-org-url"
                        value={eventCalendlyLink}
                        readOnly={
                          currentStep !== "event" ||
                          (orgAccountList && selectCalOrg !== "custom")
                        }
                        placeholder="Enter calendar link"
                        onChange={e => {
                          if (
                            currentStep === "event" &&
                            eventStatus !== EVENT_STATUS?.ended?.value
                          ) {
                            setEventCalendlyLink(e.target.value);
                          }
                        }}
                        className="custom-form-input custom-form-input-sm"
                      />
                    </Box>
                  ) : null}
                </Flex>
              ) : null}
            </Flex>
          </>
        ) : null}
      </div>
      {confirmRoom && (
        <ConfirmDialog
          open={confirmRoom ? true : false}
          data={{
            title: "Are you sure?",
            text: "Selecting this room type will allow all attendees to join with their mics and cameras enabled.",
          }}
          onCancel={() => setConfirmRoom("")}
          onAccept={onAcceptRoomType}
        />
      )}
    </>
  );
};

export default EventOptions;
