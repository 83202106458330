import React from "react";
import { useMedia } from "react-use";
import { config as cssConfig } from "@100mslive/roomkit-react";
import { NumbersIcon } from "../../icons";

const LandingHowItWork = () => {
  const isMobile = useMedia(cssConfig.media.md);
  return (
    <section className="events-page-landing-section events-page-landing-section-space events-page-landing-howitwork">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-landing-section-header">
          <h2
            className={`events-page-landing-section-header-heading ${
              isMobile ? "text-center" : ""
            } `}
          >
            How It Works
          </h2>
        </div>
        <div className="events-page-landing-howitwork-content">
          <div className="events-page-landing-howitwork-content-item">
            <div className="events-page-landing-howitwork-content-item-icon">
              <NumbersIcon type="1" />
            </div>
            <div className="events-page-landing-howitwork-content-item-title">
              We set up monthly video updates
            </div>
            <div className="events-page-landing-howitwork-content-item-text">
              Our team handles scheduling, invitations, and reminders
            </div>
          </div>
          <div className="events-page-landing-howitwork-content-item">
            <div className="events-page-landing-howitwork-content-item-icon">
              <NumbersIcon type="2" />
            </div>
            <div className="events-page-landing-howitwork-content-item-title">
              You share company updates
            </div>
            <div className="events-page-landing-howitwork-content-item-text">
              30 minutes of presentation and Q&A with shareholders
            </div>
          </div>
          <div className="events-page-landing-howitwork-content-item">
            <div className="events-page-landing-howitwork-content-item-icon">
              <NumbersIcon type="3" />
            </div>
            <div className="events-page-landing-howitwork-content-item-title">
              We distribute recordings
            </div>
            <div className="events-page-landing-howitwork-content-item-text">
              Automated follow-up keeps investors engaged between updates
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingHowItWork;
