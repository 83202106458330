import React from "react";
import { Link } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";
import { Button } from "@mui/material";
import globe from "../../../assets/images/six/globe.gif";

const SixAboutJourney = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section events-page-landing-section-space events-page-about-section-join">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-about-section-header">
            <div className="events-page-about-section-header-heading text-center events-page-about-section-header-line">
            Want to change the world with us?
            </div>
          </div>

          <div className="events-page-about-section-join-row">
            <div className="events-page-about-section-join-row-content">
              <div className="events-page-about-section-join-row-content-heading">
              Invest Without Borders
              </div>

              <div className="events-page-about-section-join-row-content-text">
              Join us on our mission to unlock social mobility on a global scale by eliminating barriers that prevent anyone, anywhere from investing in transformative opportunities. 
              </div>
              <Button
                variant="outlined"
                to="/careers"
                component={Link}
                color="primary"
                endIcon={<ArrowForward />}
                className="events6ix-btn events6ix-btn-primary mt-20"
              >
                Careers
              </Button>
            </div>
            <div className="events-page-about-section-join-row-frame">
              <img src={globe} alt="6ix Join" />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixAboutJourney;
