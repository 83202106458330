import React from "react";

export function DeleteIcon(props) {
  return props?.active ? (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.7002 4.5H3.1002H14.3002"
        stroke="#BB4A4A"
        strokeWidth="1.77769"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.19717 4.49797V3.09811C5.19717 2.72684 5.34467 2.37078 5.60722 2.10825C5.86977 1.84573 6.22586 1.69824 6.59717 1.69824H9.39717C9.76847 1.69824 10.1246 1.84573 10.3871 2.10825C10.6497 2.37078 10.7972 2.72684 10.7972 3.09811V4.49797M12.8972 4.49797V14.297C12.8972 14.6683 12.7497 15.0243 12.4871 15.2869C12.2246 15.5494 11.8685 15.6969 11.4972 15.6969H4.49717C4.12586 15.6969 3.76977 15.5494 3.50722 15.2869C3.24467 15.0243 3.09717 14.6683 3.09717 14.297V4.49797H12.8972Z"
        stroke="#BB4A4A"
        strokeWidth="1.77769"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.44824 4.24475H2.91759H14.6724"
        stroke="#8C8E95"
        strokeWidth="1.67926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.122 4.24474V2.77538C5.122 2.38569 5.2768 2.01195 5.55236 1.73639C5.82792 1.46084 6.20165 1.30603 6.59135 1.30603H9.53005C9.91975 1.30603 10.2935 1.46084 10.569 1.73639C10.8446 2.01195 10.9994 2.38569 10.9994 2.77538V4.24474M13.2034 4.24474V14.5302C13.2034 14.9199 13.0486 15.2936 12.7731 15.5692C12.4975 15.8447 12.1238 15.9996 11.7341 15.9996H4.38732C3.99762 15.9996 3.62389 15.8447 3.34833 15.5692C3.07278 15.2936 2.91797 14.9199 2.91797 14.5302V4.24474H13.2034Z"
        stroke="#8C8E95"
        strokeWidth="1.67926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DeleteIcon;
