import React from "react";
import { ScoreIcon } from "../../icons";

const LandingScore = () => {
  return (
    <section className="events-page-landing-section events-page-landing-section-fill events-page-landing-section-space events-page-landing-score">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-landing-score-content">
          <div className="events-page-landing-score-content-item">
            <div className="events-page-landing-score-content-item-icon">
              <ScoreIcon type="graph" />
            </div>
            <div className="events-page-landing-score-content-item-title">
              2x
            </div>
            <div className="events-page-landing-score-content-item-text">
              companies are 2x more likely to maintain share price stability
              with regular updates than without
            </div>
          </div>
          <div className="events-page-landing-score-content-item">
            <div className="events-page-landing-score-content-item-icon">
              <ScoreIcon type="video" />
            </div>
            <div className="events-page-landing-score-content-item-title">
              100%
            </div>
            <div className="events-page-landing-score-content-item-text">
              full-service - we handle everything, you just present
            </div>
          </div>
          <div className="events-page-landing-score-content-item">
            <div className="events-page-landing-score-content-item-icon">
              <ScoreIcon type="user" />
            </div>
            <div className="events-page-landing-score-content-item-title">
              400+
            </div>
            <div className="events-page-landing-score-content-item-text">
              public companies trust 6ix for investor retention
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingScore;
