import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, FormControl, TextareaAutosize } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Box, Flex, Input } from "@100mslive/roomkit-react";
import { Edit, UploadFIle } from "../../icons";
import ImageCropper from "../Common/ImageCropper";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import EventHostedAdd from "./EventHostedAdd";
import EventOptions from "./EventOptions";
import EventPreviewBox from "./EventPreviewBox";
import EventSpeakerAdd from "./EventSpeakerAdd";
import { SESSION_AUTH } from "../../common/auth";
import { EVENT_SERVICES } from "../../services";
import { EVENT_LEVEL, EVENT_STATUS, EVENT_TYPE } from "../../common/constants";

const EventEdit = props => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [eventData, setEventData] = useState("");
  const [eventStatus, setEventStatus] = useState("");
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState(null);
  const [eventDescription, setEventDescription] = useState("");
  const [eventCover, setEventCover] = useState("");
  const [eventCoverPreview, setEventCoverPreview] = useState("");
  const [eventCoverdefault, setEventCoverdefault] = useState("");
  const [eventID, setEventID] = useState("");
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [disableSurvey, setDisableSurvey] = useState(false);
  const [speakersData, setSpeakersData] = useState([]);
  const [eventTBD, setEventTBD] = useState(false);
  const [eventType, setEventType] = useState(EVENT_TYPE?.private?.value);
  const [eventLevel, setEventLevel] = useState(EVENT_LEVEL?.webinar?.value);
  const [eventCalendlyLinkAdd, setEventCalendlyLinkAdd] = useState(false);
  const [eventCalendlyLink, setEventCalendlyLink] = useState("");
  const [eventChatQA, setEventChatQA] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [hostedBy, setHostedBy] = useState([]);
  const [roomType, setRoomType] = useState(10000);
  const [cropEventCover, setCropEventCover] = useState("");
  const [eventMeetingReq, setEventMeetingReq] = useState(true);

  useEffect(() => {
    if (isUserSession && isUserSession?.isVerify) {
      setUserData(isUserSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);
    try {
      setLoading(true);
      EVENT_SERVICES.editEvent({
        userId: userData.userId,
        org: userData?.org?.id,
        id: eventId,
      })
        .then(data => {
          if (data.code === 200) {
            setEventData(data.data);
            setEventCoverdefault(data.data.eventCover);
            if (data.data.eventDate) {
              setEventDate(data.data.eventDate);
            } else {
              setEventTBD(true);
            }
            setEventDescription(
              data.data.eventDescription.replace(/<br\s?\/?>/g, "\n")
            );
            if (data?.data?.speakers) {
              setSpeakersData(data?.data?.speakers);
            }
            if (data?.data?.hostedBy) {
              setHostedBy(data?.data?.hostedBy || []);
            }
            setEventStatus(data.data?.eventStatus);
            setEventName(data.data.eventName);
            setEventID(data.data._id);
            if (data.data?.disableSurvey) {
              setDisableSurvey(data.data.disableSurvey);
            }
            setEventMeetingReq(data?.data?.eventMeetingReq || false);

            if (data.data?.eventType) {
              setEventType(data.data?.eventType);
            }

            if (data.data?.eventLevel) {
              setEventLevel(data.data?.eventLevel);
            }

            if (data.data?.eventCalendlyLink) {
              setEventCalendlyLinkAdd(true);
              setEventCalendlyLink(data.data?.eventCalendlyLink);
            }
            setEventChatQA(data.data?.eventChatQA);
            setRoomType(data.data?.roomType);

            setLoading(false);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            navigate("/dashboard");
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }

    return () => null;
  }, [userData, dataFetched, LOGOUT_USER_SESSION, eventId, navigate]);

  const checkEventField = () => {
    if (!eventName) {
      ToastManager.addToast({ title: "Please fill event name" });
      return false;
    }

    if (hostedBy?.length > 0) {
      let checkHostedAllow;
      for (let hRow of hostedBy) {
        if (hRow?.allow) {
          checkHostedAllow = true;
          break;
        }
      }
      if (!checkHostedAllow) {
        ToastManager.addToast({
          title: `Enable a minimum of one list icon within the " Hosted By " section to display the company logo and name`,
        });
        return false;
      }
    } else {
      ToastManager.addToast({
        title:
          "At least one Hosted By (Organization)needs to be connected to this event",
      });
      return false;
    }

    if (!eventDescription) {
      ToastManager.addToast({ title: "Please enter event description" });
      return false;
    }
    if (speakersData?.length > 0) {
      let checkSpeaker;
      for (let s = 0; s < speakersData?.length; s++) {
        if (!speakersData?.[s].name) {
          checkSpeaker =
            " Please create speaker profile of  " + speakersData?.[s].email;

          break;
        } else if (!speakersData?.[s].email) {
          checkSpeaker = "Please add  again speaker";
          break;
        }
      }
      if (checkSpeaker) {
        ToastManager.addToast({
          title: checkSpeaker,
        });
        return false;
      }
    }
    return true;
  };

  const saveEventWork = async () => {
    if (!checkEventField()) return false;
    if (!userData) {
      return navigate("/");
    }
    setLoading(true);
    const eventdData = new FormData();
    eventdData.append("eventName", eventName);
    eventdData.append("eventDate", eventDate);
    eventdData.append(
      "eventDescription",
      eventDescription.replace(/\n/g, "<br />")
    );
    eventdData.append("id", eventID);

    if (eventCover?.name) {
      eventdData.append("eventCover", eventCover, eventCover.name);
    }

    eventdData.append("disableSurvey", disableSurvey);

    eventdData.append("eventType", eventType);
    eventdData.append("eventLevel", eventLevel);

    eventdData.append(
      "eventCalendlyLink",
      eventCalendlyLinkAdd && eventCalendlyLink ? eventCalendlyLink.trim() : ""
    );

    eventdData.append(
      "eventMeetingReq",
      !disableSurvey ? eventMeetingReq : false
    );
    eventdData.append("eventChatQA", eventChatQA);
    eventdData.append("roomType", roomType);

    eventdData.append("userId", userData.userId);
    eventdData.append("org", userData?.org?.id);

    if (speakersData && speakersData?.length > 0) {
      eventdData.append("speakers", JSON.stringify(speakersData));
    }
    if (hostedBy && hostedBy?.length > 0) {
      let h = hostedBy
        .filter(item => item.accountId)
        .map(item => {
          return {
            accountId: item?.accountId,
            analytics: item?.analytics,
            allow: item?.allow,
          };
        });
      eventdData.append("hostedBy", JSON.stringify(h));
    }

    try {
      const data = await EVENT_SERVICES.updateEvent(eventdData);
      if (data && data.code === 200) {
        ToastManager.addToast({ title: data.message });
        return navigate("/dashboard");
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        ToastManager.addToast({ title: data.message });
      }
    } catch (e) {
      ToastManager.addToast({ title: "Error Something went wrong" });
    }
    setLoading(false);
  };

  const onSelectEventCover = e => {
    if (!e?.target?.files || e?.target?.files?.length === 0) {
      setEventCover("");
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setCropEventCover(reader.result?.toString() || "")
    );
    reader.readAsDataURL(e.target.files[0]);
    e.target.value = "";
  };
  useEffect(() => {
    if (!eventCover) {
      setEventCoverPreview(eventCoverdefault);
      return;
    }
    const objectUrl = URL.createObjectURL(eventCover);
    setEventCoverPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [eventCover, eventCoverdefault]);

  return (
    <React.Fragment>
      {eventData ? (
        <React.Fragment>
          <Flex
            className="events6ix-layout-page-middle"
            align="center"
            flex="1"
            direction="column"
          >
            <Flex className="events6ix-layout-page-top no-space-b">
              <h1 className="events6ix-layout-page-top-heading events6ix-layout-page-top-heading-lg events6ix-layout-page-top-heading-line">
                Edit Your Event
              </h1>
            </Flex>

            <Flex className="home-page-layout-content">
              <Flex className="home-page-layout-content-col">
                <Box className="home-page-layout-content-box">
                  <div className="custom-form">
                    <FormControl className="custom-form-group">
                      <label htmlFor="event-name" className="custom-form-label">
                        Event Title
                      </label>
                      <Input
                        id="event-name"
                        name="eventName"
                        placeholder="Event Title"
                        className="custom-form-input"
                        value={eventName}
                        onChange={e => {
                          if (e.target?.value?.length <= 75)
                            setEventName(
                              e.target?.value?.replace(/(<([^>]+)>)/gi, "")
                            );
                        }}
                        maxLength={75}
                      />
                    </FormControl>
                    {eventLevel === EVENT_LEVEL?.webinar?.value ? (
                      <FormControl className="custom-form-group">
                        <label
                          htmlFor="event-date"
                          className="custom-form-label"
                        >
                          Date/Time
                        </label>
                        <div className="custom-form-date">
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            localeText={{
                              clearButtonLabel: "TBD",
                              okButtonLabel: "APPLY",
                            }}
                          >
                            <DateTimePicker
                              disabled={
                                eventStatus === EVENT_STATUS?.ended?.value
                              }
                              onChange={newValue => {
                                newValue?.$d
                                  ? setEventDate(newValue.$d)
                                  : setEventDate(null);
                              }}
                              onClose={() => {
                                setEventTBD(true);
                              }}
                              minDateTime={dayjs(new Date())}
                              value={eventDate ? dayjs(eventDate) : eventDate}
                              format="ddd, MMM DD YYYY hh:mm A"
                              slotProps={{
                                actionBar: {
                                  actions: ["clear", "accept"],
                                },
                                textField: {
                                  placeholder: "Date & Time TBD",
                                },

                                mobilePaper: {
                                  className: "custom-dateTimePicker",
                                },
                                desktopPaper: {
                                  className: "custom-dateTimePicker",
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </div>
                      </FormControl>
                    ) : null}

                    {userData && (
                      <EventHostedAdd
                        hostedBy={hostedBy}
                        setHostedBy={setHostedBy}
                        currentStep="event"
                      />
                    )}
                    <FormControl className="custom-form-group">
                      <div className="home-page-layout-content-box-des ">
                        <label
                          htmlFor="event-description"
                          className="custom-form-label"
                        >
                          Event Description
                        </label>
                        <TextareaAutosize
                          id="event-description"
                          name="eventDescription"
                          value={eventDescription}
                          onChange={e => {
                            setEventDescription(
                              e.target?.value?.replace(/(<([^>]+)>)/gi, "")
                            );
                          }}
                          className="custom-form-input"
                          placeholder="Enter Description"
                          minRows={2}
                          maxRows={5}
                        />
                      </div>
                    </FormControl>

                    {userData && (
                      <EventSpeakerAdd
                        speakersData={speakersData}
                        setSpeakersData={setSpeakersData}
                        editEvent={true}
                        currentStep="event"
                      />
                    )}
                    <Flex
                      className="custom-form-group-row"
                      align="center"
                      css={{ gap: "10px", marginTop: "5px" }}
                    >
                      <div className="home-page-layout-content-preview-upload-file">
                        <label translate="no">
                          {eventCoverPreview ? (
                            <>
                              <div className="home-page-layout-content-preview-upload-file-preview">
                                <img src={eventCoverPreview} alt="eventcover" />
                              </div>
                              <Edit />
                            </>
                          ) : (
                            <>
                              <UploadFIle />
                              <span translate="yes">Upload</span>
                            </>
                          )}

                          <input
                            type="file"
                            onChange={onSelectEventCover}
                            name="eventCover"
                            className="home-page-layout-content-preview-upload-file-input"
                            accept="image/x-png,image/gif,image/jpeg,image/jpg,image/webp"
                          />
                        </label>
                      </div>
                    </Flex>
                  </div>
                </Box>
              </Flex>
              <Flex className="home-page-layout-content-col" direction="column">
                <EventPreviewBox
                  eventCoverPreview={eventCoverPreview}
                  eventName={eventName}
                  eventDate={
                    eventLevel === EVENT_LEVEL?.webinar?.value
                      ? eventDate
                      : null
                  }
                  eventTBD={
                    eventLevel === EVENT_LEVEL?.webinar?.value ? eventTBD : null
                  }
                  hostedBy={hostedBy}
                />
                <EventOptions
                  eventStatus={eventStatus}
                  userData={userData}
                  eventType={eventType}
                  roomType={roomType}
                  eventLevel={eventLevel}
                  setEventLevel={setEventLevel}
                  setRoomType={setRoomType}
                  setEventType={setEventType}
                  disableSurvey={disableSurvey}
                  setDisableSurvey={setDisableSurvey}
                  eventChatQA={eventChatQA}
                  setEventChatQA={setEventChatQA}
                  currentStep="event"
                  setEventCalendlyLink={setEventCalendlyLink}
                  eventCalendlyLink={eventCalendlyLink}
                  eventCalendlyLinkAdd={eventCalendlyLinkAdd}
                  setEventCalendlyLinkAdd={setEventCalendlyLinkAdd}
                  eventMeetingReq={eventMeetingReq}
                  setEventMeetingReq={setEventMeetingReq}
                />
              </Flex>
            </Flex>
            <Flex
              align="center"
              direction="column"
              justify="center"
              className="home-page-layout-footer"
            >
              <div className="home-page-layout-footer-inner">
                <Button
                  color="primary"
                  variant="outlined"
                  className="events6ix-btn events6ix-btn-primary events6ix-btn-md"
                  type="button"
                  onClick={() => {
                    saveEventWork();
                  }}
                >
                  Save
                </Button>
              </div>
            </Flex>
          </Flex>
        </React.Fragment>
      ) : null}

      {loading ? <FullPageProgress fixed={true} /> : null}

      {cropEventCover && (
        <ImageCropper
          size="xl"
          open={true}
          data={{
            image: cropEventCover,
            title: "Event cover",
            minWidth: 632,
            minHeight: 392,
            ratio: 632 / 392,
            feature: ["square", "freedom"],
          }}
          onCancel={() => {
            setCropEventCover("");
            onSelectEventCover();
          }}
          onUpdate={image => {
            setEventCover(image);
            setCropEventCover("");
          }}
        />
      )}
    </React.Fragment>
  );
};

export default EventEdit;
