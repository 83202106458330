import React, { useEffect, useRef, useState } from "react";
import { FormControl } from "@mui/material";
import { Flex, Input, Label } from "@100mslive/roomkit-react";
import { auth, signInWithUserEmailLink } from "../../../firebase";
import { ArrowLeft, GoogleIcon, Outlook } from "../../../icons";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { useAuthState } from "react-firebase-hooks/auth";
import { SESSION_AUTH } from "../../../common/auth";
import { isValidEmail } from "../../../common/utils";

const LoginWithMagic = props => {
  const { isUserSession } = SESSION_AUTH();
  const { handleLogin, email, setEmail, setPasswordLogin } = props;
  const [, loading, error] = useAuthState(auth);
  const [loader, setLoader] = useState(false);
  const [loginLinkMSG, setLoginLinkMSG] = useState("");
  const dataFetched = useRef(false);

  useEffect(() => {
    if (isUserSession) {
      if (handleLogin) handleLogin();
    }
  }, [isUserSession, handleLogin]);
  const loginWIthEmail = () => {
    if (email && isValidEmail(email)) {
      setLoader(true);
      signInWithUserEmailLink(email)
        .then(data => {
          if (data) {
            setLoginLinkMSG(data);
          }
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      ToastManager.addToast({ title: "Enter your email address" });
    }
  };

  useEffect(() => {
    if (error) {
      ToastManager.addToast({ title: error });
    }
  }, [error]);
  useEffect(() => {
    if (!dataFetched?.current && email) {
      loginWIthEmail();
    }
    dataFetched.current = true;
    // eslint-disable-next-line
  }, [email]);
  return (
    <>
      {loading && <FullPageProgress fixed={true} />}

      <div style={{ opacity: loader && "0" }}>
        <div className="userLogin-email custom-form">
          {loginLinkMSG ? (
            <>
              <div className="userLogin-email-msg">{loginLinkMSG}</div>
              <div className="userLogin-line"></div>
              <div className="userLogin-email-msg">Open this email in</div>

              <Flex className="userLogin-social mt-20" style={{ gap: "20px" }}>
                <a
                  href="https://mail.google.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="userLogin-social-btn"
                >
                  <GoogleIcon /> Gmail
                </a>
                <a
                  href="https://outlook.live.com/mail/0/"
                  target="_blank"
                  rel="noreferrer"
                  className="userLogin-social-btn mt-0"
                >
                  <Outlook /> Outlook
                </a>
              </Flex>

              <div
                className="userLogin-email-link text-underline mt-20"
                onClick={() => {
                  setPasswordLogin(0);
                }}
              >
                Did not get the link?
              </div>
            </>
          ) : (
            <>
              <FormControl className="custom-form-group">
                <Label className="custom-form-label" htmlFor="login-email">
                  Email
                </Label>
                <Input
                  id="login-email"
                  placeholder="your@email.com"
                  className="custom-form-input"
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                  }}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      if (!event.shiftKey) {
                        loginWIthEmail();
                      }
                    }
                  }}
                />
              </FormControl>
              <button
                type="button"
                className="userLogin-email-btn mt-10"
                onClick={loginWIthEmail}
                disabled={loader || loading}
              >
                Send Link
              </button>
            </>
          )}
        </div>
        <Flex className="userLogin-email-footer">
          <button
            type="button"
            onClick={() => {
              setPasswordLogin(0);
            }}
            className="userLogin-social-btn userLogin-email-footer-col ml-auto mr-auto"
          >
            <ArrowLeft /> Go Back
          </button>
        </Flex>
      </div>
    </>
  );
};
export default LoginWithMagic;
