import React from "react";
import { Link } from "react-router-dom";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Mail,
  Twitter,
} from "@mui/icons-material";
import bianca from "../../../assets/images/six/bianca.webp";
import daniel from "../../../assets/images/six/daniel.webp";

const SixAboutCreated = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section events-page-about-section-created">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-about-section-header">
            <div className="events-page-about-section-header-heading text-center events-page-about-section-header-line">
              Created By
            </div>
          </div>

          <div className="events-page-about-section-created-row">
            <div className="events-page-about-section-created-row-frame">
              <img src={daniel} alt="6ix Daniel" />
            </div>
            <div className="events-page-about-section-created-row-content">
              <div className="events-page-about-section-created-row-content-tag">
                CEO of 6ix
              </div>
              <div className="events-page-about-section-created-row-content-heading">
                Daniel Barankin
              </div>
              <div className="events-page-about-section-created-row-content-social">
                <Link
                  to="https://www.linkedin.com/in/daniel-barankin-7396b656/"
                  className="events-page-about-section-created-row-content-social-link"
                  target="_blank"
                  title="Linkedin"
                >
                  <LinkedIn />
                </Link>
                <Link
                  to="https://www.facebook.com/danielbarankin"
                  className="events-page-about-section-created-row-content-social-link"
                  target="_blank"
                  title="Facebook"
                >
                  <Facebook />
                </Link>
                <Link
                  to="https://twitter.com/danielbarankin"
                  className="events-page-about-section-created-row-content-social-link"
                  target="_blank"
                  title="Twitter"
                >
                  <Twitter />
                </Link>
                <Link
                  to="https://www.instagram.com/danielbarankin/"
                  className="events-page-about-section-created-row-content-social-link"
                  target="_blank"
                  title="Instagram"
                >
                  <Instagram />
                </Link>
                <Link
                  to="mailto:daniel@6ix.com"
                  className="events-page-about-section-created-row-content-social-link"
                >
                  <Mail /> daniel@6ix.com
                </Link>
              </div>
              <div className="events-page-about-section-created-row-content-text">
               <div className="mb-20">Daniel Barankin is the founding CEO & Chairman of 6ix, where he leads the company's mission to eliminate barriers to investment through connected investor communities.</div>
               <div className="mb-20">Beyond his leadership at 6ix, Daniel serves on the board of the Canada-Africa Chamber of Business, where he has been elected five consecutive times, demonstrating his long-standing commitment to fostering economic ties between Canada and African nations.</div>
               <div className="mb-20">As founder of Save Canadian Mining, Daniel has led a significant advocacy initiative to restore fairness and transparency to Canada's capital markets, particularly for junior mining companies. Working alongside industry leaders like Eric Sprott and Rob McEwen, he has championed regulatory reform to address predatory short selling practices that disproportionately impact emerging resource companies, further demonstrating his commitment to creating equitable access to capital.</div>
               <div className="mb-20">Daniel's passion for international development and community building extends to sports, where he is an owner of the Ghanaian football club Sekondi Eleven Wise F.C., a historic team founded in 1919 that now stands over 106 years old with a rich legacy in Ghanaian football.</div>
               <div className="mb-20">A serial entrepreneur, investor, advisor and board member, Daniel's interests span a diverse range of sectors. His portfolio includes founding, advising, and investing in companies ranging from B2B, consumer, crypto, entertainment, hardware, luxury travel, natural resources, nutrition, software, specialty retail, and sports. He maintains an active investment portfolio that spans from early-stage startups to late-stage growth companies and venture funds globally. His track record includes involvement with companies that have grown from seed to multi-billion dollar market capitalizations, reflecting his ability to identify transformational opportunities across traditional industry boundaries and geographical limitations.</div>
               <div className="mb-20">Through these diverse roles, Daniel brings a unique global perspective to his vision of creating investment opportunities that transcend all barriers - whether geographical, socioeconomic, educational, or institutional - to democratize access to capital and expertise for everyone.</div>
              </div>
            </div>
          </div>

          <div className="events-page-about-section-created-row">
            <div className="events-page-about-section-created-row-content events-page-about-section-created-row-content-2">
              <div className="events-page-about-section-created-row-content-tag">
                CRO of 6ix
              </div>
              <div className="events-page-about-section-created-row-content-heading">
                Bianca Pisciola
              </div>
              <div className="events-page-about-section-created-row-content-social">
                <Link
                  to="https://www.linkedin.com/in/bianca-pisciola-54597066"
                  className="events-page-about-section-created-row-content-social-link"
                  target="_blank"
                  title="Linkedin"
                >
                  <LinkedIn />
                </Link>
                <Link
                  to="https://www.facebook.com/bianca.pisciola"
                  className="events-page-about-section-created-row-content-social-link"
                  target="_blank"
                  title="Facebook"
                >
                  <Facebook />
                </Link>
                <Link
                  to="https://twitter.com/biancapisciola"
                  className="events-page-about-section-created-row-content-social-link"
                  target="_blank"
                  title="Twitter"
                >
                  <Twitter />
                </Link>
                <Link
                  to="https://www.instagram.com/biancapisciola/"
                  className="events-page-about-section-created-row-content-social-link"
                  target="_blank"
                  title="Instagram"
                >
                  <Instagram />
                </Link>
                <Link
                  to="mailto:bianca@6ix.com"
                  className="events-page-about-section-created-row-content-social-link"
                >
                  <Mail /> bianca@6ix.com
                </Link>
              </div>
              <div className="events-page-about-section-created-row-content-text">
              <div className="mb-20">Bianca Pisciola is the Co-founder and Chief Revenue Officer of 6ix, where she leads the company's mission to connect investors, companies, and experts through investor communities that transcend traditional barriers to investment.</div>
              <div className="mb-20">As a passionate advocate for market fairness, Bianca is a founding team member of Save Canadian Mining, a coalition working to restore transparency and accessibility in Canada's capital markets. In this role, she collaborates with industry luminaries including Eric Sprott, Rob McEwen, Keith Neumeyer, and Sean Roosen to champion regulatory reform that supports emerging resource companies.</div>
              <div className="mb-20">Bianca's commitment to creating opportunity extends beyond financial markets to empowering the next generation. She founded and serves on the board of One Silver Lining, a Canadian non-profit that transforms young lives through a diverse array of initiatives including conferences, benefit concerts, TEDx talks, and scholarship programs.</div>
              <div>Through these complementary roles, Bianca brings a unique perspective to her work at 6ix, combining her expertise in building revenue frameworks with a deep-rooted commitment to dismantling barriers that limit human potential—whether in financial markets or in the lives of young people seeking educational opportunities.</div>
              <div>Her multifaceted leadership approach reflects her belief that access to capital, knowledge, and opportunity should be available to everyone, regardless of their background or starting point.</div>
              </div>
            </div>
            <div className="events-page-about-section-created-row-frame">
              <img src={bianca} alt="6ix Bianca" />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixAboutCreated;
