import React from "react";
import Logo from "../../../assets/images/6ixlogo.png";

const SixAboutContent = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section events-page-landing-section-space events-page-about-section-about">
        <div className="events-page-about-section-about-content">
          <div className="events-page-about-section-header">
            <div className="events-page-about-section-header-heading events-page-about-section-header-heading2 text-center">
              About 6ix
            </div>
            <div className="events-page-about-section-header-heading text-center">
            Invest Without Borders
            </div>
          </div>

          <div className="events-page-about-section-header-line">
            <div className="events-page-about-section-about-content-logo">
              <img src={Logo} alt="logo" />
            </div>
          </div>

          <div className="events-page-about-section-header-text text-center mt-0">
          Our mission is to unlock social mobility on a global scale
          by eliminating barriers to investment. 
          </div>

          <div className="events-page-about-section-header-text text-center mt-0">
          Our vision is to empower anyone, anywhere to invest capital, attract capital, and share expertise  through investor communities  hosted right here on 6ix.
          </div>

        </div>
      </section>
    </React.Fragment>
  );
};

export default SixAboutContent;
