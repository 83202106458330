import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import Moment from "moment";
import { Flex } from "@100mslive/roomkit-react";
import { useGlobalValue } from "../../GlobalContext";
import {
  ArrowRight,
  CalenderIcon,
  ClockIcon,
  CopyIcon,
  DeleteIcon,
  DownLoadIcon,
  Edit,
  GraphIcon,
  ReplayIcon,
  SpeakerIcon,
  VerticalDots,
} from "../../icons";
import { EVENT_LEVEL, EVENT_STATUS } from "../../common/constants";
export function DashboardEventList(props) {
  const {
    listItem,
    actionCard,
    setActionCard,
    ToggleState,
    selectDownloadEvent,
    CopyText,
    selectDeleteEvent,
    selectReplayEvent,
    userData,
  } = props;
  const { setLoginUserVerify } = useGlobalValue();

  return (
    <Box className="product-launch-box">
      <Box className="product-launch-box-inner">
        {actionCard && actionCard._id === listItem._id ? (
          <div className="product-launch-box-actionBox">
            <div
              onClick={() => {
                setActionCard("");
                CopyText(listItem?.eventMeetingLink);
              }}
              className="product-launch-box-actionBox-item"
            >
              <span className="product-launch-box-actionBox-item-icon">
                <CopyIcon />
              </span>
              Copy URL
            </div>
            {(listItem?.isOwner || listItem?.isEdit) &&
            (ToggleState === "hosted" || ToggleState === "attended") ? (
              <>
                <div
                  className="product-launch-box-actionBox-item"
                  onClick={() => {
                    selectDownloadEvent(listItem);
                    setActionCard("");
                  }}
                >
                  <span className="product-launch-box-actionBox-item-icon">
                    <DownLoadIcon />
                  </span>
                  Downloads
                </div>
              </>
            ) : null}

            {(listItem?.isOwner || listItem?.isEdit) &&
            (ToggleState === "hosted" || ToggleState === "upcoming") ? (
              <>
                {ToggleState === "hosted" &&
                listItem?.eventStatus === EVENT_STATUS?.ended.value ? (
                  <div
                    className="product-launch-box-actionBox-item"
                    onClick={() => {
                      selectReplayEvent(listItem);
                      setActionCard("");
                    }}
                  >
                    <span className="product-launch-box-actionBox-item-icon m-l-20">
                      <ReplayIcon />
                    </span>
                    Replay
                  </div>
                ) : null}
                <Link
                  to={`/event/edit/${listItem._id}`}
                  className="product-launch-box-actionBox-item"
                  onClick={() => {
                    setActionCard("");
                  }}
                >
                  <span className="product-launch-box-actionBox-item-icon">
                    <Edit />
                  </span>
                  Edit Event
                </Link>
                <div
                  className="product-launch-box-actionBox-item delete"
                  onClick={() => {
                    selectDeleteEvent(listItem);
                    setActionCard("");
                  }}
                >
                  <span className="product-launch-box-actionBox-item-icon">
                    <DeleteIcon />
                  </span>
                  Delete
                </div>
              </>
            ) : null}
          </div>
        ) : null}

        <div className="product-launch-box-heading" title={listItem.eventName}>
          {listItem.eventName}
        </div>
        <span className="product-launch-box-hosted">
          Hosted by{" "}
          <b>
            {listItem?.hostedBy
              ? listItem?.hostedBy?.map((item, index) => (
                  <React.Fragment key={index}>
                    <Link
                      className="text-link"
                      to={`/company/${item?.orgSlug}`}
                    >
                      {item?.orgName}
                    </Link>
                    {index !== listItem?.hostedBy.length - 1 && ", "}
                  </React.Fragment>
                ))
              : listItem?.eventHostBy}
          </b>
        </span>
        <div className="product-launch-box-date-area">
          {listItem?.eventLevel === EVENT_LEVEL?.webinar?.value ? (
            <div className="product-launch-box-date-area-text">
              <CalenderIcon />{" "}
              {listItem.eventDate
                ? Moment(listItem.eventDate).format("ddd, MMM DD, YYYY")
                : "TBD"}
            </div>
          ) : null}
          {listItem.eventDate ? (
            <div className="product-launch-box-date-area-text">
              <ClockIcon /> {Moment(listItem.eventDate).format("hh:mm A")}
            </div>
          ) : null}
        </div>
        <Flex className="product-launch-box-count">
          {(listItem?.isOwner || listItem?.analyticsAccess) &&
          (ToggleState === "hosted" || ToggleState === "upcoming") ? (
            <>
              <div className="product-launch-box-count-col">
                <span className="product-launch-box-count-label">
                  Registrants:{" "}
                </span>
                <span className="product-launch-box-count-value">
                  {listItem?.totalRegister}
                </span>
              </div>

              {ToggleState === "hosted" ? (
                <div className="product-launch-box-count-col">
                  <span className="product-launch-box-count-label">
                    Attendees:{" "}
                  </span>
                  <span className="product-launch-box-count-value">
                    {listItem?.totalAttends}
                  </span>
                </div>
              ) : null}
            </>
          ) : null}
        </Flex>

        <div className="product-launch-box-footer">
          <Link
            to={listItem.eventMeetingLink}
            className="product-launch-box-footer-btn common-btn-2 hover-btn-effect stroke"
          >
            Enter Room <ArrowRight />{" "}
          </Link>
          {(listItem?.isOwner || listItem?.analyticsAccess) &&
          (ToggleState === "hosted" || ToggleState === "upcoming") ? (
            <>
              <Link
                to={
                  userData?.isVerify ? `/event/analytics/${listItem._id}` : null
                }
                className="product-launch-box-footer-btn common-btn-2 hover-btn-effect fill com-tooltip"
                onClick={() => {
                  if (!userData?.isVerify) {
                    setLoginUserVerify(true);
                  }
                }}
              >
                <GraphIcon />{" "}
                <span className="com-tooltip-text">Analyze Event</span>{" "}
              </Link>

              <Link
                to={listItem.eventMeetingLink}
                className="product-launch-box-footer-btn common-btn-2 hover-btn-effect fill stroke com-tooltip"
              >
                <SpeakerIcon />
                <span className="com-tooltip-text">Promote Event</span>
              </Link>
            </>
          ) : null}

          <Link
            onClick={() => {
              if (userData?.isVerify) {
                setActionCard(listItem?._id === actionCard._id ? "" : listItem);
              } else {
                setLoginUserVerify(true);
              }
            }}
            className="product-launch-box-footer-btn common-btn-2 hover-btn-effect fill stroke com-tooltip"
          >
            <VerticalDots />
          </Link>
        </div>
      </Box>
    </Box>
  );
}
