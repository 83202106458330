import React from "react";
import { Box, Flex } from "@100mslive/roomkit-react";
import defaultBanner from "../../assets/images/company/profile-banner.jpg";
import { LogoIcon, VerifedIcon } from "../../icons";

const CompanyBanner = props => {
  const { orgName, orgBanner, orgLogo, orgLogoColor, isVerified, hostedEvent } =
    props;
  return (
    <Box className="event-page-company-banner">
      <Box className="event-page-company-banner-top">
        <img
          src={orgBanner || defaultBanner}
          alt="Banner"
          className="event-page-company-banner-top-img"
        />
      </Box>

      <Flex className="event-page-company-banner-bottom">
        <div className="event-page-company-banner-bottom-logo">
          <div
            className="event-page-company-banner-bottom-logo-inner"
            style={{
              backgroundColor: orgLogo ? orgLogoColor : "",
            }}
          >
            {orgLogo ? <img src={orgLogo} alt="logo" /> : <LogoIcon />}
          </div>
        </div>
        <div className="event-page-company-banner-bottom-info">
          <Flex className="event-page-company-banner-bottom-info-name">
            {orgName} {isVerified ? <VerifedIcon /> : <></>}
          </Flex>
          {hostedEvent ? (
            <div className="event-page-company-banner-bottom-info-text">
              {hostedEvent} {hostedEvent > 1 ? "Events" : "Event"} Hosted
            </div>
          ) : (
            <></>
          )}
        </div>
      </Flex>
    </Box>
  );
};

export default CompanyBanner;
