import { apiCall, apiCallMultipart } from "./servicesCall";

export const DASHBOARD_SERVICES = {
  hosted(data) {
    return apiCall("/dashboard/hosted", data);
  },
  upcoming(data) {
    return apiCall("/dashboard/upcoming", data);
  },
  attended(data) {
    return apiCall("/dashboard/attended", data);
  },
  delete(data) {
    return apiCall("/dashboard/delete", data);
  },
  getReplay(data) {
    return apiCall("/dashboard/replay", data);
  },
  addReplay(data, onUploadProgress) {
    return apiCallMultipart("/dashboard/replay/add", data, onUploadProgress);
  },
  deleteReplay(data) {
    return apiCall("/dashboard/replay/delete", data);
  },
};
